import { setAuthToken } from 'src/utils/setAuthToken';
import { Auth, Profile } from '@api/requests';
import { setCurrentUserTokens, setCurrentUserData } from '../actions/auth';

export const getUser = token => async dispatch => {
  setAuthToken(token);
  const user = await Profile.userData();
  dispatch(setCurrentUserData(user.data));
};

export const loginThunk = logData => async dispatch => {
  const tokens = await Auth.login(logData);
  setAuthToken(tokens.access_token);
  localStorage.setItem(
    'jwt',
    JSON.stringify({
      jwt: tokens.access_token,
      exp: tokens.expires_in,
      ref: tokens.refresh_token,
    })
  );
  dispatch(setCurrentUserTokens(tokens));
  dispatch(getUser(tokens.access_token));
};

export const successLogin = tokens => async dispatch => {
  setAuthToken(tokens.access_token);
  localStorage.setItem(
    'jwt',
    JSON.stringify({
      jwt: tokens.access_token,
      exp: tokens.expires_in,
      ref: tokens.refresh_token,
    })
  );
  dispatch(
    setCurrentUserTokens({
      jwt: tokens.access_token,
      exp: tokens.expires_in,
      ref: tokens.refresh_token,
    })
  );
  dispatch(getUser(tokens.access_token));
};
