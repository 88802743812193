import React, { Suspense } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Home } from 'src/pages/Home/Home';
import { CoreLoader } from '@components/CoreLoader/CoreLoader';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '@theme/customTheme';
import { PrivateRoute } from 'src/components/PrivateRoute/PrivateRoute';
import { ScrollLayout } from '@components/ScrollLayout/ScrollLayout';
import { CoreLayout } from 'src/components/CoreLayout/CoreLayout';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import { NotificationsLine } from 'src/components/NotificationsLine/NotificationsLine';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
// import { Assesment } from './pages/Home/Assesment/Assesment';
import { store } from './store';

import './App.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const history = createBrowserHistory();

const Account = React.lazy(() => import('./pages/Account/Account'));
const Login = React.lazy(() => import('./pages/Login/Login'));
const CreateAccount = React.lazy(() => import('./pages/CreateAccount/CreateAccount'));
const EmailConfirm = React.lazy(() => import('./pages/EmailConfirm/EmailConfirm'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword/ResetPassword'));
const CreateNewPassword = React.lazy(() => import('./pages/ResetPassword/CreateNewPassword/CreateNewPassword'));
const PartnerShip = React.lazy(() => import('./pages/PartnerShip/PartnerShip'));
const EulerHermes = React.lazy(() => import('./pages/EulerHermes/EulerHermes'));
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('./pages/TermsConditions/TermsConditions'));

const DiscrepancyReport = React.lazy(() => import('./pages/DiscrepancyReport/DiscrepancyReport'));
const Xero = React.lazy(() => import('./pages/Xero/Xero'));
const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));
const AboutUsPage = React.lazy(() => import('./pages/AboutUsPage/AboutUsPage'));
const FAQPage = React.lazy(() => import('src/pages/FAQPage/FAQPage'));
const AssessmentPage = React.lazy(() => import('src/pages/AssessmentPage/AssessmentPage'));
const CalculatorPage = React.lazy(() => import('src/pages/CalculatorPage/CalculatorPage'));
const AngryAtYourCustomersPage = React.lazy(() =>
  import('src/pages/AngryAtYourCustomersPage/AngryAtYourCustomersPage')
);
const PricingPage = React.lazy(() => import('src/pages/PricingPage/PricingPage'));
const RegisterWizardPasswordConfirm = React.lazy(() =>
  import('src/pages/RegisterWizardPasswordConfirm/RegisterWizardPasswordConfirm')
);
const CalendarAuthCallback = React.lazy(() => import('src/pages/CalendarAuthCallback/CalendarAuthCallback'));

export const App = () => {
  return (
    <ErrorBoundary>
      <CoreLayout>
        <Provider store={store}>
          <Router history={history}>
            <Elements stripe={stripePromise}>
              <ScrollLayout>
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <CssBaseline />
                    <NotificationsLine />
                    <Suspense fallback={<CoreLoader />}>
                      <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/login" component={Login} exact />
                        <Route path="/register" component={CreateAccount} exact />
                        <Route path="/register/confirmation" component={EmailConfirm} exact />
                        <Route path="/register-wizard/confirmation" component={RegisterWizardPasswordConfirm} exact />
                        <Route path="/password-reset" component={ResetPassword} exact />
                        <Route path="/password-reset/verify" component={CreateNewPassword} exact />
                        <Route path="/partnership" component={PartnerShip} exact />
                        <Route path="/euler-hermes" component={EulerHermes} exact />
                        <Route path="/report/:userId-:name-:companyId" component={DiscrepancyReport} exact />
                        <Route path="/xero" component={Xero} />
                        <Route path="/home-page" component={HomePage} />
                        {/* <Route path="/assessment" component={Assesment} exact /> */}
                        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                        <Route path="/terms-and-conditions" component={TermsConditions} exact />
                        <Route path="/about" component={AboutUsPage} exact />
                        <Route path="/faq" component={FAQPage} exact />
                        <Route path="/assessment" component={AssessmentPage} exact />
                        <Route path="/calculator" component={CalculatorPage} exact />
                        <Route path="/angry-at-your-customers" component={AngryAtYourCustomersPage} exact />
                        <Route path="/pricing" component={PricingPage} exact />
                        <Route path="/calendar/callback/:provider" component={CalendarAuthCallback} exact />

                        <PrivateRoute path="/account" component={Account} />

                        <Route path="/notfound" component={NotFound} exact />
                        <Redirect to="/" />
                      </Switch>
                    </Suspense>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </ScrollLayout>
            </Elements>
          </Router>
        </Provider>
      </CoreLayout>
    </ErrorBoundary>
  );
};
