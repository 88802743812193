import { makeStyles } from '@material-ui/core/styles';
import { grey_dark, green_b, black_main } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  gridRoot: {
    flexGrow: 1,
  },
  spanText: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    color: grey_dark,
    fontWeight: 'normal',
  },
  checkboxTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width:480px)': {
      flexWrap: 'wrap',
    },
  },

  toggleAllBtn: {
    color: green_b,
    cursor: 'pointer',
  },
  textBody2: {
    height: 'auto',
    fontWeight: 600,
    color: black_main,
  },
  checkboxTitleText: {
    height: 'auto',
    fontWeight: 600,
    color: black_main,
    '@media (max-width:480px)': {
      marginBottom: '16px',
    },
  },
  textCheckBox: {
    height: 'auto',
    color: black_main,
  },
  managmentTitleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  managmentTitle: {
    marginRight: '18px',
  },
  textBody1: {
    fontWeight: '600',
    color: black_main,
  },
  formControlLabel: {
    '@media (max-width:480px)': {
      '&.MuiFormControlLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0,
        flexDirection: 'row-reverse',
      },
    },
  },
  tooltipInfoText: {
    padding: '0 10px',
    width: '250px',
  },
}));
