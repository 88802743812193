import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  errorBoundaryBox: {
    background: 'white',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 20px',
  },
  errorBoundaryImg: {
    width: '200px',
    height: '200px',
    margin: '80px auto',
    '@media (max-width:480px)': {
      margin: '40px auto',
    },
  },
  errorBoundaryText: {
    marginBottom: '20px',
    '@media (max-width:480px)': {
      fontSize: '16px',
    },
  },
  btnBox: {
    width: '190px',
  },
  errorBoundaryTitle: {
    '@media (max-width:480px)': {
      fontSize: '24px',
    },
  },
}));
