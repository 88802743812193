import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Feedback } from './Feedback';

import { useStyles } from './styles';

const FEEDBACK = [
  {
    title: 'Practical and effective',
    description: 'We followed the RIABU way and saw an immediate change in our receivables',
    company: 'Peter Hone – VP Modure Resources ',
  },
  {
    title: 'Get paid faster, learn lots about your customers',
    description: 'Solving your receivables issues is just the first benefit of joining RIABU…',
    company: 'Bill Padfield, NTT',
  },
  {
    title: 'Get set up to win',
    description:
      'A small company like mine needs to get set up right – and RIABU had everything I needed to get paid on time',
    company: 'Margaret Sellergren - Nestify   ',
  },
];

export const AboutUs = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mb={8}>
        <Typography className={classes.title}>What people say about us</Typography>
      </Box>
      <Grid container spacing={4}>
        {FEEDBACK.map(({ title, description, company }) => (
          <Grid key={title} item xs={12} sm={4}>
            <Feedback title={title} description={description} company={company} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
