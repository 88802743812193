import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Wrapper } from 'src/shared/Wrapper';
import { StyledInputLabel } from 'src/components/common/StyledInputLabel';
import { StyledIconButton } from 'src/components/common/StyledIconButton';
import { isMobile } from 'src/utils/mobileDetect';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useStyles } from '../CompaniesListItem/styles';

export const AccessedCompanyItem = props => {
  const { index, data, onLeaveCompany } = props;
  const { phone_number, name } = data.company;

  const classes = useStyles();
  return (
    <Wrapper padding="16px 24px" shadow="rgba(0, 0, 0, 0.15) 4px 6px 32px">
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs={12}>
              <StyledInputLabel nottu="true" nomar="false">
                S.No
                {index + 1}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.companyNameBox}>
                <Typography className={classes.companyName} variant="h6">
                  {name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.companyPhone}>{phone_number}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Grid container spacing={3} justify={isMobile ? 'flex-end' : 'flex-start'}>
            <Grid item>
              <Box className={classes.successBtn}>Employee</Box>
            </Grid>
            <Grid item>
              <StyledIconButton wid="48px" hei="48px" className={classes.trashIcon} onClick={() => onLeaveCompany(data.id)}>
                <ExitToAppIcon />
              </StyledIconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
