import { makeStyles } from '@material-ui/core/styles';
import { black_light } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  footerBox: {
    background: '#F5F5F7',
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      padding: '16px 0 20px 0',
    },
  },
  item: {
    flexShrink: 0,
    '@media (max-width: 900px)': {
      marginBottom: '8px',
    },
  },
  footerText: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '24px',
    color: `${black_light} !important`,
    flexShrink: 0,
    textTransform: 'auto',
    '& a': {
      color: `${black_light} !important`,
      textDecoration: 'auto',
    },
  },
  list: {
    display: 'flex',
  },
  listItem: {
    flexShrink: 0,
    marginRight: '16px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
  icon: {
    color: '#09121F',
    width: '24px',
    height: '24px',
  },
}));
