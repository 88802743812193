import React from 'react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { CenteredBox } from '../../../../components/common/CenteredBox';

export const PaginatedContainer: React.FC<any> = props => {
  const { children, currentPage = 1, lastPage = 1, onChange = () => {}, ...otherProps } = props;

  function handleChange(_: any, page: number) {
    onChange(page);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <CenteredBox>
          <Pagination
            size="small"
            color="primary"
            count={lastPage}
            page={currentPage}
            onChange={handleChange}
            {...otherProps}
          />
        </CenteredBox>
      </Grid>
    </Grid>
  );
};
