import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useStyles } from './styles'

const Pledge2Pay = () => {
    const classes = useStyles();
    return (
        <Box className={classes.Pledge2PayBox}>
            <Typography className={classes.Pledge2PayText}>
                BE PART OF THE MOVEMENT 
            <br/>TAKE A Pledge<i>2Pay</i> NOW!
            </Typography>
            <Button href='https://www.pledge2pay.com/'color="primary"
                variant='contained' className={classes.Pledge2PayBtn}>
                    Pledge<i>2Pay</i>
            </Button>
        </Box>
    )
}

export default Pledge2Pay