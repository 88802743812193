import { makeStyles } from '@material-ui/core/styles';
import Pledge2PlayBg from '@assets/img/Pledge2PlayBg.jpg';

export const useStyles = makeStyles(theme => ({
    Pledge2PayBox: {
        textAlign: "center",
        width: "100%",
        padding: "10%",
        backgroundImage: `url(${Pledge2PlayBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: "10px",
    },
    Pledge2PayText: {
        fontSize: "30px",
        lineHeight: "40px",
        fontWeight: "bold",
        color: "#1A1A1A",
        '@media (max-width:950px)': {
            fontSize: "20px",
            lineheight: "25px",
        },
    },
    Pledge2PayBtn: {
        marginTop: "22px",
        width: "120px",
        '@media (max-width:600px)': {
            fontSize: "11px",
            width: "100px",
        },
    },
}));
