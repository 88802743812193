import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isLogged = useSelector(state => state.user.isAuthenticated);
  const currentSubscription = useSelector(state => state.user?.currentUserData?.current_subscription);
  const greetingWizardShow = useSelector(state => state.user?.currentUserData?.greeting_wizard?.show);
  return (
    <Route
      {...rest}
      render={props => {
        if (!isLogged) {
          return <Redirect to="/login" />;
        }
        if (greetingWizardShow && location.pathname !== '/account/dashboard') {
          return <Redirect to="/account/dashboard" />;
        }

        if (
          currentSubscription?.length === 0 &&
          !greetingWizardShow &&
          location.pathname !== '/account/subscriptions'
        ) {
          return <Redirect to="/account/subscriptions" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
