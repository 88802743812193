import { makeStyles } from '@material-ui/core/styles';
import { black_light, grey_light, green_b } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    flexGrow: 1,
  },

  academySection: {
    paddingBottom: '120px',
  },
  header: {
    marginBottom: '32px',
  },
  content: {
    padding: '0 56px',
    '@media (max-width:480px)': {
      padding: '0',
    },
  },
  academyImgBanner: {
    display: 'block',
    width: '392px',
    height: 'auto',
    '@media (max-width:480px)': {
      width: '100%',
      height: '192px',
    },
  },
  subscribeBlockTitle: {
    fontSize: '40px',
    lineHeight: '56px',
    letterSpacing: '0.01em',
    color: '#000000',
    '@media (max-width:480px)': {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  subscribeBlockSubTitle: {
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '0.01em',
    color: black_light,
    '@media (max-width:480px)': {
      fontSize: '14px',
      lineHeight: '24px',
      marginBottom: '24px',
    },
  },
  gridMobileItem: {
    '@media (max-width:480px)': {
      marginBottom: '16px',
    },
  },
  baseItem: {
    padding: '40px',
    background: grey_light,
    height: '100%',
    '@media (max-width:480px)': {
      padding: '20px',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  textBox: {
    flexGrow: 1,
  },
  titleBox: {
    marginBottom: '16px',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  linkText: {
    color: green_b,
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '24px',
    paddingRight: '15px',
  },
  imageBox: {
    '@media (max-width:480px)': {
      marginBottom: '16px',
    },
  },
  image: {
    display: 'block',
    width: '192px',
    height: 'auto',
    '@media (max-width:480px)': {
      width: '100%',
      height: '192px',
    },
  },
  descriptionBox: {
    '@media (max-width:480px)': {
      marginBottom: '24px',
    },
  },
}));
