import { api } from '../index';

export const hidden = notificationId => api.post(`/notification/read/${notificationId}`);

export const getSettings = () => api.get('/notification/settings');

export const getTemplates = () => api.get('/notification/templates');

export const updateCustomerSettings = data => api.post('/notification/settings/customer', data);

export const setNotificationsTime = data => api.post('/notification/settings/common', data);

export const updateTemplate = data => api.post('/notification/template/save', data);
