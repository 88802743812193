import React from 'react';
import { Box, Select, FormHelperText, MenuItem, Checkbox } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { grey_light, black_main, red_b, grey_dark } from '@theme/customTheme';
import { CustomMenuItem } from '@components/common/CustomMenuItem';

import { ExtendedInputLabel } from './label/ExtendedInputLabel';
import { getMarkType } from './label/getMarkType';

export const CustomSelectFormComponent = ({
  renderValue = val => val,
  withCheckBox = false,
  multiple = false,
  variant = 'outlined',
  placeholder = 'false',
  onHandleChange,
  nottu = 'false',
  helperText = '',
  options,
  value,
  error = false,
  label,
  name,
  helperErrorText,
  helperErrorTextMark = '',
  isValMarkVisible = false,
  helperTextPosAbsl = false,
}) => {
  const markType = getMarkType({ error: helperErrorText || helperErrorTextMark, visible: isValMarkVisible, value });
  return (
    <Box>
      {label && <ExtendedInputLabel nottu={nottu} label={label} markType={markType} />}
      <StyledSelect
        renderValue={renderValue}
        multiple={multiple}
        name={name}
        displayEmpty
        error={error || helperErrorText}
        value={value}
        variant={variant}
        placeholder={placeholder === 'false ' ? null : placeholder}
        onChange={onHandleChange}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
        }}
        disabled={!options.length}
      >
        {/* {placeholder !== 'false' && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )} */}
        {options.map(({ id, optionsValue, title }) => (
          <CustomMenuItem value={optionsValue} key={id}>
            {withCheckBox && multiple && <Checkbox checked={value.indexOf(optionsValue) > -1} />}
            {title}
          </CustomMenuItem>
        ))}
      </StyledSelect>
      {(helperText || helperErrorText) && (
        <StyledFormHelperText helperTextPosAbsl={helperTextPosAbsl}>
          {helperText || helperErrorText}
        </StyledFormHelperText>
      )}
    </Box>
  );
};

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    position: 'relative',
    color: ({ placeholder, value }) => placeholder !== 'false' && value === '' && grey_dark,
    '&.MuiSelect-select': {
      fontSize: '16px',
    },
  },
  '&.MuiFilledInput-root': {
    borderRadius: '4px ',
  },
  '& .MuiSelect-filled': {
    height: 'auto',
    background: grey_light,
  },
  '& .MuiFilledInput-input': {
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    color: black_main,
    borderRadius: '4px',
  },
  '&.MuiSelect-selectMenu': {
    fontSize: '16px !important',
  },
});

const StyledFormHelperText = styled(FormHelperText)({
  '&.MuiFormHelperText-root': {
    transition: '.3s',
    margin: '0 !important',
    marginTop: '2px !important',
    fontSize: '10px',
    lineHeight: '13px',
    color: red_b,
    position: ({ helperTextPosAbsl }) => (helperTextPosAbsl ? 'absolute' : 'relative'),
  },
});

export const CustomSelectForm = React.memo(CustomSelectFormComponent);
