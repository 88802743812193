import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, InputLabel, Typography } from '@material-ui/core';

import DateMomentUtils from '@date-io/moment';
import { StepProps } from '../../types';

import { useStyles } from './styles';

const Step1 = ({ formik }: StepProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h3" className={classes.stepTitle}>
        When do you plan to deliver your product/service?
      </Typography>
      <Typography variant="h6" className={classes.stepSubText}>
        This is so we can guide you through the prompt payment process
      </Typography>
      <Box className={classes.inputWrapper}>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <InputLabel className={classes.label}>Delivery Date</InputLabel>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="outlined"
            format="MM/DD/yyyy"
            placeholder="MM/DD/YYYY"
            margin="normal"
            id="date-picker-inline"
            value={formik.values.delivery_date}
            onChange={date => formik.setFieldValue('delivery_date', date || null)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputLabelProps={{
              disabled: true,
            }}
            className={classes.datePicker}
            helperText={formik.touched.delivery_date && formik.errors.delivery_date}
            error={Boolean(formik.touched.delivery_date && formik.errors.delivery_date)}
            DialogProps={{
              style: { zIndex: 1800 },
              PaperProps: {
                style: { zIndex: 1800 },
              },
              BackdropProps: {
                style: { zIndex: 1700 },
              },
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};

export default Step1;
