import React, { useState } from 'react';

import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box, Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { FormValues } from './types';
import { useStyles } from './styles';

interface HomePageWizardProps {
  steps: React.ComponentType<{ formik: FormikProps<FormValues> }>[];
  onSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  validationSchema: Yup.ObjectSchema<any>[];
  submissionSuccess: boolean;
  isSubmitting: boolean;
}

const HomePageWizard = ({
  steps,
  onSubmit,
  initialValues,
  validationSchema,
  submissionSuccess,
  isSubmitting,
}: HomePageWizardProps) => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep === steps.length - 1;
  const currentValidationSchema: Yup.ObjectSchema<any> = validationSchema[currentStep];

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: values => {
      if (isLastStep) {
        onSubmit(values);
      } else {
        setCurrentStep(currentStep + 1);
      }
    },
  });

  const StepComponent = steps[currentStep];

  function getButtonLabel() {
    if (isLastStep) {
      return 'Send';
    }
    return 'Next';
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.stepWrapper}>
        <StepComponent formik={formik} />
      </Box>
      {submissionSuccess && (
        <Box className={classes.submitSuccess}>
          <CheckCircleIcon color="primary" />
          <Typography className={classes.submitSuccessText}>
            Confirmation has been successfully sent to your mailbox
          </Typography>
        </Box>
      )}
      <Box>
        <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={isSubmitting}>
          {getButtonLabel()}
        </Button>
      </Box>
    </form>
  );
};

export default HomePageWizard;
