/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import joinImg from '@assets/home/spam-info/join.png';
import { CenteredBox } from '@components/common/CenteredBox';
import laptopImg from '@assets/home/spam-info/laptop.png';
import { grey_potty } from '@theme/customTheme';
import { showSuccessNotification } from '@components/common/Notifications';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/icons/arrow-right.svg';
import { ReactComponent as BlocksIcon } from '@assets/svg/icons/blocks.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useStyles } from './styles.js';

export const SpamInfoSection = () => {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);
  const [copyValue, setCopyValue] = useState('Service@riabu.com');

  // useEffect(() => {
  //   if (copied) {
  //     console.log()
  //     showSuccessNotification('Copied Success');
  //   }
  // }, [copied]);

  return (
    <Box className={classes.spamInfoSection}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={classes.baseItem} style={{ background: grey_potty }}>
            <Grid container spacing={5}>
              <Grid item xs>
                <Box className={classes.contentBox}>
                  <Box className={classes.textBox}>
                    <Box className={classes.titleBox}>
                      <Typography variant="h6">Know your customers!</Typography>
                    </Box>
                    <Box className={classes.descriptionBox}>
                      <Typography variant="body2">
                        Join the RIABU Telegram Channel for updates on the payment conduct of specific customers.
                      </Typography>
                    </Box>
                  </Box>

                  <a href="https://t.me/RIABU_receivables" target="_blank" className={classes.linkBox}>
                    <Typography className={classes.linkText}>Join us</Typography>
                    <CenteredBox className={classes.ArrowIconBox}>
                      <ArrowRightIcon />
                    </CenteredBox>
                  </a>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box className={classes.imageBox}>
                  <img className={classes.image} src={joinImg} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={classes.baseItem} style={{ background: grey_potty }}>
            <Grid container spacing={5}>
              <Grid item xs>
                <Box className={classes.contentBox}>
                  <Box className={classes.textBox}>
                    <Box className={classes.titleBox}>
                      <Typography variant="h6">We hear you!</Typography>
                    </Box>
                    <Box className={classes.descriptionBox}>
                      <Typography variant="body2">
                        Copy us when you send reminder emails to your most difficult customers, and we will give you
                        pointers on how to manage them.
                      </Typography>
                    </Box>
                  </Box>

                  <div className={classes.linkBox}>
                    <Typography className={classes.linkText}>Service@riabu.com</Typography>
                    <CopyToClipboard text={copyValue}>
                      <CenteredBox
                        className={classes.ArrowIconBox}
                        onCopy={() => setCopied(true)}
                        onClick={() => showSuccessNotification('Copied Success')}
                      >
                        <BlocksIcon />
                      </CenteredBox>
                    </CopyToClipboard>
                  </div>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box className={classes.imageBox}>
                  <img className={classes.image} src={laptopImg} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
