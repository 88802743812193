import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';

export const Container = styled(Box)({
  padding: '0 56px',
  margin: '0 auto',
  '@media (max-width:480px)': {
    padding: '0 20px',
  },
});
