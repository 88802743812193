import * as yup from 'yup';
import { errorMessages } from './errorMessages';

export const FIRST_NAME = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(2, errorMessages.FIRST_NAME_MIN)
  .max(255, errorMessages.MAX_255);

export const LAST_NAME = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(2, errorMessages.LAST_NAME_MIN)
  .max(255, errorMessages.MAX_255);

export const TILE = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(2, errorMessages.TILE)
  .max(255, errorMessages.MAX_255);

export const LINKEDIN = yup
  .string()
  .matches(
    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter valid url'
  )
  .required(errorMessages.REQUIRED_FIELD);

export const EMAIL = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .max(255, errorMessages.MAX_255)
  .email(errorMessages.EMAIL_INVALID);

export const PASSWORD = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(8, errorMessages.PASSWORD_MIN)
  .max(64, errorMessages.MAX_64)
  .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, errorMessages.PASSWORD_VALID);

export const PASSWORD_CONFIRMATION = yup
  .string()
  .oneOf([yup.ref('password')], errorMessages.PASSWORD_CONFIRMATION_MATCH)
  .required(errorMessages.REQUIRED_FIELD);

export const PHONE = yup.string().required(errorMessages.REQUIRED_FIELD);

export const COMPANY = yup.string().required(errorMessages.REQUIRED_FIELD);

export const COMPANY_QUERY = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(3, errorMessages.MIN_3)
  .max(255, errorMessages.MAX_255);

export const REQUIREDFIELD = yup.string().required(errorMessages.REQUIRED_FIELD).max(255, errorMessages.MAX_255);

export const COMPANY_AUTOCOMPLETE = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(3, errorMessages.COMPANY_AUTOCOMPLETE);

export const WEBSITE = yup.string().url().required(errorMessages.REQUIRED_FIELD);

export const RECOMMENDATION = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(12, errorMessages.RECOMMENDATION_MIN)
  .max(500, errorMessages.RECOMMENDATION_MAX);

export const RECOMMENDATION_REVIEW = yup
  .string()
  .min(12, errorMessages.RECOMMENDATION_MIN)
  .max(500, errorMessages.RECOMMENDATION_MAX);

export const INSTRUCTIONS = yup.string().max(500, errorMessages.RECOMMENDATION_MAX);

export const RATING = yup.number().moreThan(1, errorMessages.RATING);
export const INVOICE_NUMBER = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(1, errorMessages.MIN_1)
  .max(255, errorMessages.MAX_255);

export const CORRECT_INFORMATION_CONFIRMATION = yup
  .number()
  .min(1, errorMessages.CONFIRMATION_PRODUCT_DELIVERED_ACCORDING_SPECIFICATION);

export const CONFIRMATION_PRODUCT_DELIVERED_ACCORDING_SPECIFICATION = yup
  .number()
  .min(1, errorMessages.CONFIRMATION_PRODUCT_DELIVERED_ACCORDING_SPECIFICATION);

export const CONFIRMATION_INVOICE_ISSUED_INSTRUCTION_OF_CUSTOMER = yup
  .number()
  .min(1, errorMessages.CONFIRMATION_INVOICE_ISSUED_INSTRUCTION_OF_CUSTOMER);

export const GRANT_PERMISSION = yup.number().min(1, errorMessages.GRANT_PERMISSION);

export const PREFER_TO_MEET = yup.number().min(1, errorMessages.PREFER_TO_MEET);

export const DATE = yup.string().required(errorMessages.DATE);
export const PERSON = yup.string().required(errorMessages.PERSON);
export const DISCREPANCY_IDENTIFIED = yup.number().required(errorMessages.DISCREPANCY_IDENTIFIED);
export const COMMENTS = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(12, errorMessages.RECOMMENDATION_MIN)
  .max(500, errorMessages.MAX_500);

export const NAME = yup
  .string()
  .required(errorMessages.REQUIRED_FIELD)
  .min(1, errorMessages.MIN_1)
  .max(255, errorMessages.MAX_255);

export const EMAIL_ARRAY = yup
  .array()
  .transform(str => {
    return str
      .join(',')
      .split(',')
      .map(el => el.trim());
  })
  .of(yup.string().max(255, errorMessages.MAX_255).email(errorMessages.EMAIL_INVALID));

export const getEmailArrayRule = rule =>
  rule
    .transform(str => {
      return str
        .join(',')
        .split(',')
        .map(el => el.trim());
    })
    .of(yup.string().max(255, errorMessages.MAX_255).email(errorMessages.EMAIL_INVALID));

export const CARD = yup.bool().oneOf([true], 'Card is not valid');
export const CVC = yup.bool().oneOf([true], 'CVC is not valid');
export const EXPIRE = yup.bool().oneOf([true], 'Expire is not valid');

export const DISTANCE_DAYS = yup.number().min(0, errorMessages.POSITIE_NUM).max(500, errorMessages.MAX_500_NUM);

export const POSITIVE_NUMBER = yup.number().min(0, errorMessages.POSITIE_NUM).required(errorMessages.REQUIRED_FIELD);
