import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F9FBFD',
    marginTop: 40,
  },

  leftColumn: {
    padding: 40,
    //paddingTop: '10%',

    [theme.breakpoints.down('sm')]: {
      order: 2,
      margin: 0,
      padding: 24,
    },
  },
  button: {
    width: 240,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bannerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
}));
