import React from 'react';
// import { useHistory } from 'react-router-dom';
// import { Autocomplete } from '@material-ui/lab';
// import SearchIcon from '@material-ui/icons/Search';
// import { useDebounce } from 'use-debounce';
// import { useStyles } from './styles/searchCompanyStyles';
// import { ICompanySearch, search } from '../../../../api/requests/company';

import { Box } from '@material-ui/core';
import { SearchCompanies } from 'src/pages/MyCompanies/MyProfile/SearchCompanies/SearchCompanies';
import { ClaimedCompanies } from '../../../../pages/MyCompanies/Companies/ClaimedCompanies.jsx';

interface Param {
  isLogged: Boolean;
}

export const SearchCompany: React.FC<Param> = () => {
  // const classes = useStyles();
  // const navigate = useHistory();
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [inputValue, setInputValue] = useState<string>('');
  // const [debounceInputValue] = useDebounce(inputValue, 333);
  // const [companies, setCompanies] = useState<ICompanySearch[]>([]);
  // const [selectCompany, setSelectCompany] = useState<ICompanySearch | null>(null);

  // useEffect(() => {
  //   if (inputValue) {
  //     setIsLoading(true);
  //   }
  // }, [inputValue]);

  // useEffect(() => {
  //   if (selectCompany) {
  //     navigate.push(`account/company/info/${selectCompany.id}`);
  //   }
  //   return setSelectCompany(null);
  // }, [navigate, selectCompany]);

  // useEffect(() => {
  //   if (debounceInputValue) {
  //     search(debounceInputValue)
  //       .then(res => {
  //         setCompanies(res.data);
  //       })
  //       .finally(() => setIsLoading(false));
  //   }
  // }, [debounceInputValue]);

  // return isLogged ? (
  //   <Box>
  //     <SearchCompanies homeSearch={true} onTriggerUpdateListData={ClaimedCompanies} />
  //   </Box>
  // ) : (
  //   <Autocomplete
  //     loading={isLoading}
  //     onChange={(_, newValue) => {
  //       return setSelectCompany(newValue);
  //     }}
  //     inputValue={inputValue}
  //     onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
  //     id="custom-input-demo"
  //     options={companies}
  //     getOptionLabel={(option: ICompanySearch) => option.name}
  //     renderInput={params => (
  //       <div className={classes.inputWrapper} ref={params.InputProps.ref}>
  //         <SearchIcon />
  //         <input {...params.inputProps} className={classes.input} type="text" />
  //       </div>
  //     )}
  //   />
  // );

  return (
    <Box>
      <SearchCompanies homeSearch onTriggerUpdateListData={ClaimedCompanies} />
    </Box>
  );
};
