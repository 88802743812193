import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { withStyles } from '@material-ui/core/styles';

import { isExternalLink } from 'src/utils/isExternalLink';
import { HeaderMenuProps } from '../../types';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
})(MuiAccordionDetails);

const AccordionSummaryText = withStyles({
  root: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    color: '#77CC21',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
})(Typography);

const AccordionDetailsItem = withStyles({
  root: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    color: '#1A1A1A',
    fontWeight: 600,
    padding: '15px 0',
  },
})(Typography);

export default function MenuAccordion({ menuText, menuItems }: HeaderMenuProps) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const history = useHistory();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleMenuItemClick = useCallback(
    (path: string) => {
      if (isExternalLink(path)) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }
    },
    [history]
  );

  return (
    <div>
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <AccordionSummaryText>
            {menuText}
            {expanded ? (
              <ArrowDropUpIcon style={{ color: '#77CC21', fontSize: '24px', marginLeft: '-8px' }} />
            ) : (
              <ArrowDropDownIcon style={{ color: '#77CC21', fontSize: '24px', marginLeft: '-8px' }} />
            )}
          </AccordionSummaryText>
        </AccordionSummary>
        <AccordionDetails>
          {menuItems.map(item => (
            <AccordionDetailsItem key={item.text} onClick={() => handleMenuItemClick(item.path)}>
              {item.text}
            </AccordionDetailsItem>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
