import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Popover, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { green_b, red_b, theme } from '@theme/customTheme';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/icons/arrow-left.svg';
import { ReactComponent as HelpCircleIcon } from '@assets/svg/icons/help-circle.svg';
import { ReactComponent as PlusIcon } from '@assets/svg/icons/plus.svg';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '@shared/Wrapper';
import { isMobile } from '@utils/mobileDetect';
import { CenteredBox } from '@components/common/CenteredBox';
import { StyledIconButton } from '@components/common/StyledIconButton';
import { useStyles } from './styles';

export const CabinetHeader = ({
  title,
  companyTitle,
  addBtnAction,
  onAddBtnAction,
  addBtn = true,
  isDisableBtn,
  tooltipText = '',
  style = {},
  addBtnText = '',
}) => {
  const classes = useStyles();
  // const [actionIcon, setActionIcon] = useState(true);
  const history = useHistory();

  const [anchorInfoTooltip, setAnchorInfoTooltip] = useState(null);

  const handleShowInfoTooltip = event => {
    setAnchorInfoTooltip(event.currentTarget);
  };

  const handleHiddenInfoTooltip = () => {
    setAnchorInfoTooltip(null);
  };

  const handleActionBtn = () => {
    // setActionIcon(addBtnAction);
    onAddBtnAction();
  };

  const showInfoTooltip = Boolean(anchorInfoTooltip);

  return (
    <Box style={style}>
      <Grid container className={classes.gridRoot}>
        <Grid item xs={12}>
          <Grid container spacing={isMobile ? 0 : 5} className={classes.gridContainerHeader}>
            <Grid item className={classes.gridBackBtn}>
              <StyledIconButton className={classes.backBtn} color="primary" onClick={() => history.goBack()}>
                <ArrowLeftIcon className={classes.ArrowLeftIcon} />
              </StyledIconButton>
            </Grid>
            <Grid item className={classes.gridTitle} xs>
              {isMobile ? (
                <Wrapper padding={isMobile && !companyTitle ? '20px 12px' : '12px 12px'}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Box className={classes.titleBox}>
                        <Typography variant="h5" className={classes.sectionTitle}>
                          {title}
                        </Typography>
                        <Typography className={classes.titleCompany}>{companyTitle}</Typography>
                      </Box>
                    </Grid>
                    {!isMobile && (
                      <Grid item>
                        <CenteredBox className={classes.helpIconBox}>
                          <HelpCircleIcon />
                        </CenteredBox>
                      </Grid>
                    )}
                  </Grid>
                </Wrapper>
              ) : (
                <Wrapper padding="16px 18px">
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Box className={classes.titleBox}>
                        <Typography variant="h5" className={classes.sectionTitle}>
                          {title}
                        </Typography>
                        <Typography className={classes.titleCompany}>{companyTitle}</Typography>
                      </Box>
                    </Grid>
                    {!isMobile && Boolean(tooltipText.length) && (
                      <Grid item>
                        <Box
                          aria-haspopup="true"
                          onMouseEnter={handleShowInfoTooltip}
                          onMouseLeave={handleHiddenInfoTooltip}
                          aria-owns={showInfoTooltip ? 'mouse-over-popover' : undefined}
                        >
                          <CenteredBox className={classes.helpIconBox}>
                            <HelpCircleIcon />
                          </CenteredBox>
                          <HoverPopover
                            id="mouse-over-popover"
                            open={showInfoTooltip}
                            anchorEl={anchorInfoTooltip}
                            onClose={handleHiddenInfoTooltip}
                            style={{ top: '4%', left: '-3%', position: 'absolute' }}
                          >
                            <p className={classes.tooltipPaperText}>{tooltipText}</p>
                          </HoverPopover>
                        </Box>

                        {/* <CenteredBox className={classes.helpIconBox}>
                          <HelpCircleIcon />
                        </CenteredBox> */}
                      </Grid>
                    )}
                  </Grid>
                </Wrapper>
              )}
            </Grid>
            {addBtn && (
              <Grid item>
                <Box>
                  {addBtnText ? (
                    <Button
                      onClick={handleActionBtn}
                      isAdd={!addBtnAction}
                      disabled={isDisableBtn}
                      className={classes.addNewButton}
                      variant="contained"
                      color="primary"
                    >
                      {addBtnText}
                    </Button>
                  ) : (
                    <CustomIconButton onClick={handleActionBtn} isAdd={!addBtnAction} disabled={isDisableBtn}>
                      <PlusIcon className="plusIcon" />
                    </CustomIconButton>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomIconButton = styled(StyledIconButton)({
  background: ({ isAdd }) => (isAdd ? green_b : red_b),
  '&:hover': {
    background: ({ isAdd }) => (isAdd ? green_b : red_b),
  },
  '& .plusIcon': {
    transition: '.3s',
    transform: ({ isAdd }) => (!isAdd ? 'rotate(45deg)' : 'rotate(0deg)'),
  },
});

const HoverPopover = withStyles({
  root: {
    pointerEvents: 'none',
    boxShadow: theme.shadows[3],
  },
})(Popover);
