import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  menuButton: {
    fontFamily: 'Open Sans',
    color: '#1A1A1A',
    fontWeight: 600,
    fontSize: '16px',
    '&:focus': { border: 0, outline: 0 },
  },
  menuButtonIcon: {
    color: '#77CC21',
    fontSize: '24px',
    marginLeft: '-8px',
  },
  menuItem: {
    '&.MuiMenuItem-root > .MuiListItemText-root > .MuiTypography-root': {
      fontFamily: 'Open Sans',
      color: '#1A1A1A',
      fontWeight: 600,
    },
  },
});
