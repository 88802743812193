import React from 'react';
import { showSuccessNotification } from 'src/components/common/Notifications';
import { handleInvalidError } from 'src/utils/handleInvalidError';
import { addAccessForEmployee } from 'src/api/requests/multiAccess';
import { AddEmployeeForm } from './AddEmployeeForm';

interface IAddEmployeeFormContainer {
  onAddEmployee: (employee: any) => any;
}
export const AddEmployeeFormContainer: React.FC<IAddEmployeeFormContainer> = props => {
  const { onAddEmployee } = props;
  const onSubmit = async (data: any, formik: any) => {
    try {
      const response = await addAccessForEmployee(data);
      if (response) {
        onAddEmployee(response.data);
        // @ts-ignore
        showSuccessNotification(response.message, 3000);
      }
    } catch (error) {
      handleInvalidError(formik.setErrors)(error);
    } finally {
      formik.setSubmitting(false);
    }
  };
  return <AddEmployeeForm onSubmit={onSubmit} />;
};
