import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as QuotesIcon } from '@assets/home/quotes.svg';

import { useStyles } from './styles';

export const Feedback = ({ title, description, company }) => {
  const classes = useStyles();

  if (!description || !title || !company) return null;

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#77CC21', fontSize: 24, fontWeight: 'bold' }}>{title}</Typography>
      <Box mt={3.5} mb={3.5}>
        <Typography style={{ color: '#040C14', fontSize: 18 }}>{description}</Typography>
      </Box>
      <Typography style={{ color: '#040C14', fontSize: 18, fontWeight: 'bold', flex: 1 }}>{company}</Typography>
      <Box mt={8}>
        <Grid container justifyContent="flex-end">
          <QuotesIcon />
        </Grid>
      </Box>
    </div>
  );
};
