/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { RootRef, Paper, Typography } from '@material-ui/core';
import clsx from 'classnames';
import { ReactComponent as PaperClipIcon } from '@assets/svg/icons/paperclip.svg';
import { ReactComponent as FileIcon } from '@assets/svg/icons/file.svg';
import { ReactComponent as TimesIcon } from '@assets/svg/icons/times.svg';
import { black_main } from '@theme/customTheme';
import { CenteredBox } from '@components/common/CenteredBox';
import { useStyles } from './styles';

const DropZoneComponent = ({ onUploadedFiles, links = [], customPlaceholder = '' }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const { ref, ...rootProps } = getRootProps();

  const [filesArr, setFilesArr] = useState([]);

  const classes = useStyles();

  const handleDeleteFile = fileName => {
    const tempArr = filesArr.filter(({ name }) => name !== fileName);
    setFilesArr(tempArr);
  };

  useEffect(() => {
    if (filesArr.length) {
      onUploadedFiles(filesArr);
    }
  }, [filesArr]);

  useEffect(() => {
    const elIsArr = filesArr.find(({ name }) => {
      if (acceptedFiles.length) {
        return acceptedFiles[0].name === name;
      }
      return 0;
    });

    if (!elIsArr) {
      const tempArr = [...acceptedFiles];
      setFilesArr(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const localFiles = filesArr.map(file => (
    <li key={file.path} className={classes.fileListItem}>
      <CenteredBox className={classes.fileIconBox}>
        <FileIcon />
      </CenteredBox>
      <CenteredBox>{file.path}</CenteredBox>
      <CenteredBox className={classes.timesIconBox} onClick={() => handleDeleteFile(file.name)}>
        <TimesIcon fill={black_main} />
      </CenteredBox>
    </li>
  ));

  // links  - list of urls(Array<string>). If link is not string - we ignore this element
  const otherFiles =
    links &&
    links
      .filter(link => typeof link === 'string')
      .map(link => {
        return (
          <li key={link} className={classes.fileListItem}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <CenteredBox className={classes.fileIconBox}>
                <FileIcon />
              </CenteredBox>
            </a>
            <CenteredBox>{link}</CenteredBox>
          </li>
        );
      });

  return (
    <RootRef rootRef={ref}>
      <>
        <Paper {...rootProps} className={classes.paper}>
          <input {...getInputProps()} />
          <CenteredBox className={classes.textBox}>
            <CenteredBox className={classes.paperClipBox}>
              <PaperClipIcon />
            </CenteredBox>
            <Typography className={classes.text}>
              {customPlaceholder || 'Drop your credit policy here, or'}

              <span className={classes.textSpan}> browse</span>
            </Typography>
          </CenteredBox>
        </Paper>
        <ul
          className={clsx(classes.fileList, {
            [classes.fileListMargin]: localFiles.length > 0 || otherFiles.length > 0,
          })}
        >
          {localFiles}
          {otherFiles}
        </ul>
      </>
    </RootRef>
  );
};

export const DropZone = React.memo(DropZoneComponent);
