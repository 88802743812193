import { showErrorNotification, showWarnNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';

export const handleInvalidError = setErrors => error => {
  const { status } = error.response;
  const { data } = error.response.data;
  const { errors } = error.response.data;
  if (status === 422) {
    setErrors(data || errors);
    showWarnNotification('Validation Error', 2000);
  } else {
    showErrorNotification(err.DEFAULT);
  }
};
