import React, { useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';

import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { isExternalLink } from 'src/utils/isExternalLink';
import { useStyles } from './styles/headerDropdownMenu';
import { HeaderMenuProps } from '../../types';

const HeaderDropdownMenu = ({ menuText, menuItems }: HeaderMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (path: string) => {
      if (isExternalLink(path)) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }
      setAnchorEl(null);
    },
    [history]
  );

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menuButton}
        endIcon={
          anchorEl ? (
            <ArrowDropUpIcon style={{ color: '#77CC21', fontSize: '24px', marginLeft: '-8px' }} />
          ) : (
            <ArrowDropDownIcon style={{ color: '#77CC21', fontSize: '24px', marginLeft: '-8px' }} />
          )
        }
      >
        {menuText}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disablePortal
        getContentAnchorEl={null}
        style={{ marginTop: '8px' }}
      >
        {menuItems.map(item => (
          <MenuItem key={item.text} onClick={() => handleMenuItemClick(item.path)} className={classes.menuItem}>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default HeaderDropdownMenu;
