import { makeStyles } from '@material-ui/core/styles';
import { black_main, grey_dark } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  root: {},
  gridRoot: {
    flexGrow: 1,
  },
  gridSearch: {
    flexGrow: 1,
  },
  autocompleteWrapper: {
    flexGrow: 1,
  },
  searchPaper: {
    borderRadius: '16px',
    padding: '12px 18px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #D8DFE6',
  },
  searchIcon: {
    marginRight: '18px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchAutocomplete: {
    '& input': {
      border: 'none',
      outline: 'none',
      boxSizing: 'border-box',
      color: black_main,
      fontSize: '16px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '24px',
        color: grey_dark,
        letterSpacing: '0.02em',
      },
    },
    '& .MuiAutocomplete-paper': {
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    '& .MuiList-root': {
      padding: '0px',
    },
  },
  sectionHeaderText: {
    lineHeight: '32px',
    fontWeight: 600,
  },
  searchAutocompleteInput: {
    outline: 'none',
    border: 'none',
    color: black_main,
    fontSize: '14px',
    lineHeight: '24px',
    width: '100%',
    '&::placeholder': {
      fontSize: '14px',
      color: grey_dark,
      lineHeight: '24px',
      textTransform: 'capitalize',
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  },
  customOption: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  customOptionLoggedOut: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:hover $homeAddCustomerBtns': {
      display: 'none',
    },
    '&:hover $homeRegisterBtn': {
      display: 'block',
    },
  },
  homeOptionHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '0px',
  },
  optionHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
  },
  optionHeadingTitle: {
    flexGrow: 1,
    display: 'block',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '16px',
      maxWidth: '310px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '500px',
    },
  },
  optionHeadingTitleWrapper: {
    paddingRight: '8px',
  },
  homeOptionHeadingBtn: {
    padding: '5px',
    fontSize: '15px',
  },
  homeAddCustomerBtn: {
    padding: '5px',
    fontSize: '15px',
    marginTop: '5px',
  },
  optionHeadingBtn: {
    width: '120px',
  },
  optionInfoListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '17px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  optionInfoTitle: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: grey_dark,
  },
  optionInfoValue: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: black_main,
  },
  homeAddCustomerBtns: {},
  homeRegisterBtn: {
    display: 'none',
    padding: '7px 0',
  },
  homeRegisterBtnLoggedIn: {
    display: 'none',
    padding: '7px 0',
  },
  registerBtn: {
    padding: '4px 10px',
    borderRadius: '16px',
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '2px',
    marginBottom: '3px',
    width: 130,
  },

  paper: {
    backgroundColor: 'white',
    width: '100%',
    position: 'absolute',
    boxShadow: '4px 6px 32px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 10,
      width: '340px',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-60%)',
      marginTop: theme.spacing(1),
    },
  },
  buttonsWrapper: {
    width: 410,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
