import { withStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import { grey_label } from '@theme/customTheme';

export const StyledInputLabel = withStyles({
  root: {
    fontSize: '10px',
    color: grey_label,
    lineHeight: '16px',
    marginBottom: ({ nomar }) => (nomar === 'false' ? '0px' : '4px'),
    textTransform: ({ nottu }) => (nottu === 'true' ? 'none' : 'uppercase'),
  },
})(InputLabel);
