import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { Wrapper } from '@shared/Wrapper';
import { isMobile } from '@utils/mobileDetect';

import { StyledInputLabel } from '@components/common/StyledInputLabel';
import { StyledIconButton } from '@components/common/StyledIconButton';
import { ReactComponent as EditIcon } from '@assets/svg/icons/edit-pan-line.svg';
import { ReactComponent as TrashIcon } from '@assets/svg/icons/trash.svg';

import { BasePieChart } from 'src/components/common/BasePieChart';
import { useStyles } from './styles';

export const CompaniesListItem = ({ data, onDeleteCompany, index }) => {
  const classes = useStyles();

  const { name } = data.company;
  const { phone_number, status, id, company_id } = data;

  function getStatusBlock() {
    if (status === 0) {
      return (
        <Grid item>
          <Box className={classes.pendingBtn}>Pending</Box>
        </Grid>
      );
    }
    if (status === 3) {
      // Status 3 - Declined
      return (
        <Grid item>
          <Box className={classes.declinedBtn}>Declined</Box>
        </Grid>
      );
    }
    return (
      <Grid item style={{ flexGrow: 1 }}>
        <Link to={`/account/my-companies-edit/${id}/${company_id}`}>
          {isMobile ? (
            <Button color="q" variant="outlined" size="large">
              Edit
            </Button>
          ) : (
            <StyledIconButton wid="48px" hei="48px" className={classes.editIcon}>
              <EditIcon width="24px" height="24px" />
            </StyledIconButton>
          )}
        </Link>
      </Grid>
    );
  }

  return (
    <Wrapper padding="16px 24px" shadow="rgba(0, 0, 0, 0.15) 4px 6px 32px">
      <Grid container justify="space-between" alignItems="center" spacing={isMobile ? 2 : 0}>
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs={12}>
              <StyledInputLabel nottu="true" nomar="false">
                S.No
                {index + 1}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.companyNameBox}>
                <Typography className={classes.companyName} variant="h6">
                  {name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.companyPhone}>{phone_number}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Grid container spacing={3} justify={isMobile ? 'flex-end' : 'flex-start'}>
            <Grid item>
              <BasePieChart value={data.percent_complete} size="48px" />
            </Grid>
            {getStatusBlock()}
            <Grid item>
              <StyledIconButton wid="48px" hei="48px" className={classes.trashIcon} onClick={() => onDeleteCompany(id)}>
                <TrashIcon width="24px" height="24px" />
              </StyledIconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
