import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { StyledIconButton } from 'src/components/common/StyledIconButton';
import { ReactComponent as TrashIcon } from 'src/assets/svg/icons/trash.svg';
import { IEmployee, onDeleteAccessType } from './types';
import { pendingBtn, successBtn, transparentTrashIcon } from '../../../MyProfile/CompaniesListItem/styles';
import { StyledTableCell, StyledTableRow } from '../../../../../components/common/SimpleTable/SimpleTable';

interface IEmployeeRowProps {
  onDeleteAccess: onDeleteAccessType;
  row: IEmployee;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
  pendingBtn: {
    ...pendingBtn,
    maxWidth: '100px',
  },
  successBtn: {
    ...successBtn,
    maxWidth: '100px',
  },
  trashIcon: transparentTrashIcon,
}));

export const EmployeeRow: React.FC<IEmployeeRowProps> = props => {
  const { row, onDeleteAccess } = props;
  const classes = useStyles();
  // @ts-ignore
  return (
    <StyledTableRow>
      <StyledTableCell align="left">{row.id}</StyledTableCell>
      <StyledTableCell align="center">{row.email || 'No email'}</StyledTableCell>
      <StyledTableCell align="center">{row.name || 'No name'}</StyledTableCell>
      <StyledTableCell align="center">{row.position || 'No position'}</StyledTableCell>
      <StyledTableCell align="center">{row.company || 'No company'}</StyledTableCell>
      <StyledTableCell align="center" width="100px">
        {row.status === 0 ? (
          <div className={classes.pendingBtn}>Pending</div>
        ) : (
          <div className={classes.successBtn}>Added</div>
        )}
      </StyledTableCell>
      <StyledTableCell align="right" width="50px">
        {/* @ts-ignore */}
        <StyledIconButton wid="48px" hei="48px" className={classes.trashIcon} onClick={() => onDeleteAccess(row.id)}>
          <TrashIcon width="24px" height="24px" />
        </StyledIconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};
