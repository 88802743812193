export const featuresMenuItems = [
  { path: '/assessment', text: 'Assessment' },
  { path: '/calculator', text: 'Calculator' },
  { path: '/angry-at-your-customers', text: 'Angry at your customers?' },
];

export const learnMenuItems = [
  { path: 'https://vimeo.com/channels/riabu/ ', text: 'RIABU Academy' },
  { path: 'https://www.udemy.com/course/how-to-get-your-invoices-paid-on-time-every-time', text: 'Udemy Course' },
];
