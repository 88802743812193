import React, { useState, useEffect } from 'react';

export const MountUnmountCaptcha = ({ children }) => {
  const [mount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    return () => {
      setMount(false);
    };
  }, []);
  return <div>{mount ? children : null}</div>;
};
