/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from '@shared/Wrapper';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import { ReactComponent as PhotoCameraIcon } from '@assets/svg/icons/photo-camera.svg';
import { isMobile } from '@utils/mobileDetect';
import { ReactComponent as UploadAvatarIcon } from '@assets/svg/icons/upload-avatar.svg';
import { ReactComponent as TrashIcon } from '@assets/svg/icons/trash.svg';
import { showErrorNotification, showSuccessNotification } from '@components/common/Notifications';
import { CenteredBox } from '@components/common/CenteredBox';

import { isEmptyObj } from '@utils/isEmptyObj';

import { Profile } from '@api/requests';

import { ProfileInfoForm } from '../ProfileInfoForm/ProfileInfoForm';
import { useStyles } from './styles';

export const ProfileInfo = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarImg, setAvatarImg] = useState(null);
  const [avatarUploaded, setAvatarUploaded] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(({ user }) => user.currentUserData);
  const handleUploadAvatar = event => {
    setAvatarFile(event.target.files[0]);
    const binaryData = [];
    binaryData.push(event.target.files[0]);
    setAvatarImg(window.URL.createObjectURL(new Blob(binaryData)));
  };

  const uploadAvatar = async () => {
    try {
      let data = new FormData();
      data.append('avatar', avatarFile);
      setLoading(true);
      const res = await Profile.uploadAvatar(data);
      setAvatarUploaded(res.success);
      setLoading(false);
      showSuccessNotification('Avatar uploaded success!');
    } catch (e) {
      showErrorNotification(e.response.data.data.avatar[0]);
      setAvatarUploaded(false);
      setLoading(false);
      setError(true);
    }
  };

  const handleDeleteAvatar = async () => {
    try {
      setAvatarUploaded(false);
      setLoading(true);
      const res = await Profile.removeAvatar();
      showSuccessNotification(res.message);
      setLoading(false);
      setAvatarImg(null);
    } catch (e) {
      showErrorNotification(e.response.data.data.avatar[0]);
      setAvatarUploaded(false);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (avatarFile) {
      uploadAvatar();
    }
  }, [avatarFile]);

  useEffect(() => {
    if (!isEmptyObj(userData)) {
      if (userData.profile.avatar.length) {
        setAvatarImg(userData.profile.avatar);
        setAvatarUploaded(true);
      }
    }
  }, [userData]);

  return (
    <Wrapper padding={isMobile ? '24px' : '20px 32px 40px 32px'}>
      {isEmptyObj(userData) ? (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={isMobile ? 2 : 4} alignItems="center">
              <Grid item xs={12} md={2}>
                <Box className={classes.photoBox}>
                  <Box className={classes.uploadInputBox}>
                    <input
                      multiple
                      type="file"
                      accept="image/*"
                      name="upload-avatar"
                      onChange={handleUploadAvatar}
                      id="upload-avatar-button-file"
                      className={classes.uploadInput}
                    />
                    {!(avatarImg && avatarUploaded) ? (
                      <label htmlFor="upload-avatar-button-file">
                        <div className={classes.uploadBtn}>
                          <UploadAvatarIcon />
                        </div>
                      </label>
                    ) : (
                      <div className={classes.deleteAvatarBtn} onClick={handleDeleteAvatar}>
                        <TrashIcon width="14" height="16" />
                      </div>
                    )}
                  </Box>
                  {avatarUploaded && <img className={classes.avatarImg} src={avatarImg} alt="" />}
                  {!avatarUploaded && !loading && <PhotoCameraIcon />}
                  {loading && !error && <CircularProgress />}
                </Box>
              </Grid>
              <Grid item lg={10}>
                <ProfileInfoForm userData={userData} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};
