import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: 30,
    boxShadow: '8px 8px 12px rgba(0, 0, 0, 0.02)',
    borderRadius: 16,
    border: '1px solid #D8DFE6',
    height: '100%',
  },
}));
