/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Popover, Paper, Drawer, Divider } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { loguot } from '@store/actions/auth';

import { CustomMenuList } from '@components/common/CustomMenuList';
import { CustomMenuItem } from '@components/common/CustomMenuItem';

import { ReactComponent as UserIcon } from '@assets/svg/icons/user.svg';
import { ReactComponent as LogoIcon } from '@assets/home/logoIcon.svg';

import { SearchCompany } from 'src/components/Header/components/SearchCompany/SearchCompany';
import MenuAccordion from '../MenuAccordion/MenuAccordion';

import { useStyles } from './styles/MobileHeader';

import { featuresMenuItems, learnMenuItems } from '../../consts';

const MobileHeader = ({ onUserMenu, openUserMenu, anchorUserMenu, onUserMenuClose }) => {
  const classes = useStyles();
  const isLogged = useSelector(state => state.user.isAuthenticated);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loguot());
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prev => !prev);
  };

  return (
    <Box>
      <Box className={classes.mobileMenuWrapper}>
        <Box>{mobileOpen ? <CloseIcon onClick={handleDrawerToggle} /> : <MenuIcon onClick={handleDrawerToggle} />}</Box>
        <Box>
          <Link to="/">
            <LogoIcon />
          </Link>
        </Box>
        <Box className={classes.searchWrapper}>
          <SearchCompany />
        </Box>

        <Box>
          <Box onClick={onUserMenu}>
            <UserIcon width="24px" height="24px" fill="#000000" />
            <Popover
              open={openUserMenu}
              style={{ top: 16 }}
              anchorEl={anchorUserMenu}
              onClose={onUserMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper className={classes.paper}>
                <CustomMenuList className={classes.userActionList}>
                  {isLogged ? (
                    <Link to="/account/dashboard" className={classes.menuLink}>
                      <CustomMenuItem>My account</CustomMenuItem>
                    </Link>
                  ) : (
                    <Link to="/register" className={classes.menuLink}>
                      <CustomMenuItem>Create account</CustomMenuItem>
                    </Link>
                  )}
                  {isLogged ? (
                    <Link to="/" className={classes.menuLink} onClick={handleLogout}>
                      <CustomMenuItem>Log out</CustomMenuItem>
                    </Link>
                  ) : (
                    <Link to="/login" className={classes.menuLink}>
                      <CustomMenuItem>Login</CustomMenuItem>
                    </Link>
                  )}
                </CustomMenuList>
              </Paper>
            </Popover>
          </Box>
        </Box>
      </Box>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
        }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <Box className={classes.mobileDrawer}>
          <Box className={classes.mibileMenuItem}>
            <MenuAccordion menuText="Learn" menuItems={learnMenuItems} />
          </Box>
          <Box>
            <Divider variant="fullWidth" className={classes.divider} />
          </Box>
          <Box className={classes.mibileMenuItem}>
            <Link className={classes.menuLink} to="/about">
              About Us
            </Link>
          </Box>
          <Box>
            <Divider variant="fullWidth" className={classes.divider} />
          </Box>
          <Box className={classes.mibileMenuItem}>
            <MenuAccordion menuText="Features" menuItems={featuresMenuItems} />
          </Box>
          <Box>
            <Divider variant="fullWidth" className={classes.divider} />
          </Box>
          <Box className={classes.mibileMenuItem}>
            <Link className={classes.menuLink} to="/faq">
              FAQ
            </Link>
          </Box>
          <Box>
            <Divider variant="fullWidth" className={classes.divider} />
          </Box>
          <Box className={classes.mibileMenuItem}>
            <Link className={classes.menuLink} to="/pricing">
              Pricing
            </Link>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileHeader;
