import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as CheckboxRound } from '@assets/home/checkbox-round.svg';

export const Benefit = ({ benefit }) => {
  if (!benefit) return null;

  return (
    <Grid container spacing={1} wrap="nowrap">
      <Grid item xs="auto">
        <CheckboxRound />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontSize: 18 }} gutterBottom>
          {benefit}
        </Typography>
      </Grid>
    </Grid>
  );
};
