import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import errorImg from '@assets/svg/error-img.svg';

import { useStyles } from './styles';

export const FallBackUI = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.errorBoundaryBox}>
        <Typography variant="h2" align="center" className={classes.errorBoundaryTitle}>
          Something went wrong
        </Typography>
        <Box>
          <img src={errorImg} alt="error" className={classes.errorBoundaryImg} />
        </Box>
        <Typography variant="h5" align="center" className={classes.errorBoundaryText}>
          An error occurred. Please reload page and try again
        </Typography>
        <Box className={classes.btnBox}>
          <Button
            color="secondary"
            size="large"
            variant="outlined"
            style={{ width: '100%' }}
            onClick={() => document.location.reload(true)}
          >
            Reload
          </Button>
        </Box>
      </Box>
    </>
  );
};
