import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Situation } from './Situation';

import { useStyles } from './styles';

const SITUATIONS = [
  {
    title: 'Stop the bleed',
    description:
      'You are having big problems getting paid on time. You need practical solutions now to bring cash in faster',
  },
  {
    title: 'Get it right the first time',
    description:
      'You are a recently established company or a start-up, and you want to put solid foundations in place to impress investors with your investors with you cash flow',
  },
  {
    title: 'Apply best practise',
    description:
      'You are an established enterprise looking to strengthen collection processes and reduce your order to cash cycle ',
  },
];

export const Situations = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#A8ADB2' }} align="center">
        What is your situation?
      </Typography>
      <Box mt={3.5} mb={3.5}>
        <Typography className={classes.title} align="center">
          Act now to get paid on time, every time
        </Typography>
      </Box>
      <Box mb={5}>
        <Typography style={{ color: '#5E6369' }} align="center">
          Which best describes your situation?
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {SITUATIONS.map(({ title, description }) => (
          <Grid key={title} item xs={12} sm={4}>
            <Situation title={title} description={description} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
