import React from 'react';

import { Box, InputLabel, Typography } from '@material-ui/core';

import { CustomTextField } from 'src/components/common/CustomTextField';
import { StepProps } from '../../types';

import { useStyles } from './styles';

const Step3 = ({ formik }: StepProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h3" className={classes.stepTitle}>
        What is your email address?
      </Typography>
      <Typography variant="h6" className={classes.stepSubText}>
        This is so we can guide you through the prompt payment process
      </Typography>
      <Box className={classes.inputWrapper}>
        <InputLabel className={classes.label}>Email</InputLabel>
        <CustomTextField
          lowerCasePlaceholder
          type="email"
          name="email"
          placeholder="example@mail.com"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          helperText={formik.touched.email && formik.errors.email}
          error={Boolean(formik.touched.email && formik.errors.email)}
        />
      </Box>
    </Box>
  );
};

export default Step3;
