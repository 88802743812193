import React, { useState } from 'react';

import * as Yup from 'yup';

import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Auth } from 'src/api/requests';

import { showSuccessNotification, showErrorNotification } from 'src/components/common/Notifications';
import HomePageWizard from '../HomePageWizard';

import Step1 from '../HomePageWizardSteps/Step1/Step1';
import Step2 from '../HomePageWizardSteps/Step2/Step2';
import Step3 from '../HomePageWizardSteps/Step3/Step3';

import { FormValues } from '../types';

interface ApiResponse {
  success: boolean;
  data: string;
}

const FormContainer = () => {
  const steps = [Step1, Step2, Step3];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const initialValues: FormValues = {
    delivery_date: null,
    invoice_due_date: null,
    email: '',
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema = [
    Yup.object().shape({
      delivery_date: Yup.date()
        .min(today, 'The delivery date must be a date after or equal to today')
        .nullable()
        .typeError('Please enter a valid date')
        .required('Delivery date is required'),
    }),
    Yup.object().shape({
      invoice_due_date: Yup.date()
        .nullable()
        .typeError('Please enter a valid date')
        .min(today, 'The invoice due date must be a date after or equal to today')
        .when('delivery_date', (delivery_date, schema) =>
          delivery_date
            ? schema.min(delivery_date, 'The invoice due date must be a date after or equal to delivery date')
            : schema
        )
        .required('Invoice due date is required'),
    }),
    Yup.object({
      email: Yup.string().email('Invalid email').required('Email is required'),
    }),
  ];

  const handleFormSubmit = async (values: FormValues) => {
    const { email, invoice_due_date, delivery_date } = values;

    const formattedValues = {
      email,
      invoice_due_date: moment(invoice_due_date).format('YYYY-MM-DD'),
      delivery_date: moment(delivery_date).format('YYYY-MM-DD'),
    };

    try {
      setIsSubmitting(true);
      const response: AxiosResponse<ApiResponse> = await Auth.registerWizard(formattedValues);

      showSuccessNotification(response.data, 4500);
      setSubmissionSuccess(true);
    } catch (error) {
      // @ts-ignore
      if (error.response?.data) {
        // Axios error
        // @ts-ignore
        const serverErrorMessage = error.response?.data?.message || 'Something went wrong';
        showErrorNotification(serverErrorMessage, 4500);
      } else if (error instanceof Error) {
        // General error
        showErrorNotification(error.message, 4500);
      } else {
        showErrorNotification('An unexpected error occurred', 4500);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HomePageWizard
      steps={steps}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      submissionSuccess={submissionSuccess}
      isSubmitting={isSubmitting}
    />
  );
};

export default FormContainer;
