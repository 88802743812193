import React from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { theme } from '@theme/customTheme';

export const Wrapper = ({
  padding,
  shadow,
  borderRadius,
  children,
  border,
  isdraggingover,
  isDefaultPadding,
  style,
  ...otherProps
}) => (
  <WrapperBox
    borderrad={borderRadius}
    shadow={shadow}
    padding={padding}
    border={border}
    isdraggingover={isdraggingover}
    isDefaultPadding={isDefaultPadding}
    style={style}
    {...otherProps}
  >
    {children}
  </WrapperBox>
);

const WrapperBox = styled(Box)({
  background: ({ isdraggingover }) => (isdraggingover ? '#D7E5C2' : 'white'),
  padding: ({ padding, isDefaultPadding }) => (isDefaultPadding ? '16px 24px' : padding),
  border: ({ border }) => border,
  boxShadow: ({ shadow }) => (shadow === 'false' ? 'none' : theme.shadows[2]),
  borderRadius: ({ borderrad }) => borderrad || '16px',
  transition: 'background-color 0.2s ease',
});
