/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Box, Button, CircularProgress } from '@material-ui/core';
import { Wrapper } from '@shared/Wrapper';
import { isMobile } from '@utils/mobileDetect';
import { CustomTextField } from '@components/common/CustomTextField';
import { CustomSelectForm } from '@components/common/CustomSelectFrom';
import { CustomPhoneInput } from '@components/common/CustomPhoneInput';
import { CenteredBox } from '@components/common/CenteredBox';
import { CustomHelperText } from '@components/common/CustomHelperText';
import { CustomCheckbox } from '@components/common/CustomCheckbox';
import { showErrorNotification, showSuccessNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';

import { handleInvalidError } from '@utils/handleInvalidError';

import { StyledInputLabel } from '@components/common/StyledInputLabel';
import { DropZone } from '@components/DropZone/DropZone';

import { ClaimCompany } from '@api/requests';
import { createClaimOptions, createOptions } from 'src/utils/createOptions';

import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '@constants/validation/validation';

import { useStyles } from './styles';

export const PaymentProcessesForm = ({ claimId, companyId, collectionsData }) => {
  const classes = useStyles();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [filesArr, setFilesArr] = useState([]);
  const [termsOptions, setTermsOptions] = useState([]);
  const [procurementOptions, setProcurementOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [checkboxesValues, setCheckboxesValues] = useState([]);

  const formik = useFormik({
    initialValues: {
      website: '',
      payment_terms: '',
      invoice_per_month: '',
      procurement_portal: '',
      name_of_person_in_charge_of_accounts_payable: '',
      email: '',
      general_manager_email: '',
      ceo_email: '',
      phone_code: '',
      phone_number: '',
      file: '',
    },
    validationSchema: yup.object().shape({
      website: validation.WEBSITE,
      name_of_person_in_charge_of_accounts_payable: validation.NAME,
      email: validation.EMAIL,
      general_manager_email: validation.EMAIL,
      ceo_email: validation.EMAIL,
    }),
    onSubmit: values => {
      const data = new FormData();
      data.set('id', claimId);
      data.set('company_id', companyId);
      data.set('website', values.website);
      data.set('payment_terms', values.payment_terms);
      data.set('invoice_per_month', values.invoice_per_month);
      data.set('procurement_portal', values.procurement_portal);
      data.set('name_of_person_in_charge_of_accounts_payable', values.name_of_person_in_charge_of_accounts_payable);
      data.set('email', values.email);
      data.set('general_manager_email', values.general_manager_email);
      data.set('ceo_email', values.ceo_email);
      data.set('phone_code', values.phone_code);
      data.set('phone_number', values.phone_number);
      data.set('claim_invoice', JSON.stringify(checkboxesValues));
      data.append('file', values.file);

      ClaimCompany.upadate(data)
        .then(res => {
          formik.setSubmitting(false);
          showSuccessNotification(res.message, 3000);
        })
        .catch(e => {
          showErrorNotification(err.DEFAULT);
          handleInvalidError(formik.setErrors)(e);
          formik.setSubmitting(false);
        });
    },
  });

  const setFormikInitialValue = modelParam => {
    const {
      website,
      payment_terms,
      invoice_per_month,
      procurement_portal,
      name_of_person_in_charge_of_accounts_payable,
      email,
      general_manager_email,
      ceo_email,
      phone_code,
      phone_number,
      claim_invoice,
    } = modelParam;
    formik.setFieldValue('website', website, false);
    formik.setFieldValue('payment_terms', payment_terms, false);
    formik.setFieldValue('invoice_per_month', invoice_per_month, false);
    formik.setFieldValue('procurement_portal', procurement_portal, false);
    formik.setFieldValue(
      'name_of_person_in_charge_of_accounts_payable',
      name_of_person_in_charge_of_accounts_payable,
      false
    );
    formik.setFieldValue('email', email, false);
    formik.setFieldValue('general_manager_email', general_manager_email, false);
    formik.setFieldValue('ceo_email', ceo_email, false);
    formik.setFieldValue('phone_code', phone_code, false);
    formik.setFieldValue('phone_number', phone_number, false);
    setPhoneNumber(phone_code + phone_number);
    setCheckboxesValues(claim_invoice);
  };

  useEffect(() => {
    formik.setFieldTouched('file', true);
    formik.setFieldValue('file', filesArr[0], false);
  }, [filesArr]);

  const handlePhone = (phone, country) => {
    setPhoneNumber(phone);
    const number = phone.replace(country.dialCode, '');
    formik.setFieldValue('phone_code', country.dialCode, false);
    formik.setFieldValue('phone_number', number, false);
  };

  const uploadedFiles = files => {
    formik.setFieldTouched('file', true);
    setFilesArr(files);
  };

  const handleChangeInvoiceSettings = useCallback(
    event => {
      const { name: checkboxName, checked } = event.target;

      const checkboxesValuesClone = checkboxesValues.map(el => ({ ...el }));
      const checkboxIndx = checkboxesValuesClone.findIndex(el => el.name === checkboxName);
      const oldItem = checkboxesValuesClone[checkboxIndx];
      const newItem = {
        ...oldItem,
        is_enable: checked,
      };
      const newCheckboxData = [
        ...checkboxesValuesClone.slice(0, checkboxIndx),
        newItem,
        ...checkboxesValuesClone.slice(checkboxIndx + 1),
      ];
      setCheckboxesValues(newCheckboxData);
    },
    [checkboxesValues]
  );

  const transformDataCheckbox = checkboxesParams => {
    const checkboxesArr = [];
    for (const key in checkboxesParams) {
      checkboxesParams[key].is_enable = false;
      checkboxesArr.push(checkboxesParams[key]);
    }
    setCheckboxesValues(checkboxesArr);
  };

  const handleToggleAllCheckboxes = () => {
    const checkboxesValuesClone = checkboxesValues.map(el => ({ ...el }));
    const newCheckboxesValues = [...checkboxesValuesClone.map(el => ({ ...el, is_enable: true }))];
    setCheckboxesValues(newCheckboxesValues);
  };

  useEffect(() => {
    if (collectionsData && collectionsData.periods && collectionsData.model && collectionsData.checkbox) {
      const tempTerms = collectionsData.periods.map(({ id, name }) => createClaimOptions(id, name));
      const tempProcurementPortal = collectionsData.procurement_portal.map((value, index) =>
        createOptions(index, value)
      );
      const tempInvoicePerMonth = collectionsData.invoice_per_month.map(({ id, name }) => createClaimOptions(id, name));
      setTermsOptions(tempTerms);
      setProcurementOptions(tempProcurementPortal);
      setInvoiceOptions(tempInvoicePerMonth);
      transformDataCheckbox(collectionsData.checkbox);
      setFormikInitialValue(collectionsData.model);
    }
  }, [collectionsData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container className={classes.gridRoot} spacing={isMobile ? 4 : 6}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Information about your payment processes
                <span className={classes.spanText}> (visible to your suppliers)</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="website"
                label="website"
                onBlur={formik.handleBlur}
                value={formik.values.website}
                onChange={formik.handleChange}
                helperText={formik.touched.website && formik.errors.website}
                error={Boolean(formik.touched.website && formik.errors.website)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectForm
                name="payment_terms"
                value={formik.values.payment_terms}
                label="Standard payment term"
                options={termsOptions}
                onHandleChange={formik.handleChange}
                helperText={formik.touched.payment_terms && formik.errors.payment_terms}
                error={Boolean(formik.touched.payment_terms && formik.errors.payment_terms)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectForm
                name="invoice_per_month"
                options={invoiceOptions}
                onHandleChange={formik.handleChange}
                value={formik.values.invoice_per_month}
                label="We usually receive invoices per month"
                helperText={formik.touched.invoice_per_month && formik.errors.invoice_per_month}
                error={Boolean(formik.touched.invoice_per_month && formik.errors.invoice_per_month)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelectForm
                name="procurement_portal"
                options={procurementOptions}
                onHandleChange={formik.handleChange}
                value={formik.values.procurement_portal}
                label="Procurement Portal we use to receive invoices"
                helperText={formik.touched.procurement_portal && formik.errors.procurement_portal}
                error={Boolean(formik.touched.procurement_portal && formik.errors.procurement_portal)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Fill person name"
                name="name_of_person_in_charge_of_accounts_payable"
                label="Name of person in charge of Accounts Payable"
                value={formik.values.name_of_person_in_charge_of_accounts_payable}
                helperText={
                  formik.touched.name_of_person_in_charge_of_accounts_payable &&
                  formik.errors.name_of_person_in_charge_of_accounts_payable
                }
                error={Boolean(
                  formik.touched.name_of_person_in_charge_of_accounts_payable &&
                    formik.errors.name_of_person_in_charge_of_accounts_payable
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                type="email"
                name="email"
                label="Email"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Fill email address"
                helperText={formik.touched.email && formik.errors.email}
                error={Boolean(formik.touched.email && formik.errors.email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                type="email"
                label="General Manager"
                name="general_manager_email"
                placeholder="example@mail.com"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.general_manager_email}
                helperText={formik.touched.general_manager_email && formik.errors.general_manager_email}
                error={Boolean(formik.touched.general_manager_email && formik.errors.general_manager_email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="CEO"
                type="email"
                name="ceo_email"
                placeholder="example@mail.com"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.ceo_email}
                helperText={formik.touched.ceo_email && formik.errors.ceo_email}
                error={Boolean(formik.touched.ceo_email && formik.errors.ceo_email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <StyledInputLabel>Phone</StyledInputLabel>
                <CustomPhoneInput
                  countryCode="sg"
                  inputValue={phoneNumber}
                  name="phone_number"
                  onHandleChange={handlePhone}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.phone_number && formik.touched.phone_number)}
                />

                {Boolean(formik.errors.phone_number && formik.phone_number) && (
                  <CustomHelperText>{formik.errors.phone_number}</CustomHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <StyledInputLabel>Procurement policy</StyledInputLabel>
              <DropZone customPlaceholder="Drop your procurement policy here" onUploadedFiles={uploadedFiles} />
              {Boolean(formik.errors.file) && <CustomHelperText>{formik.errors.file}</CustomHelperText>}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={classes.checkboxTitleBox}>
                <Typography variant="body2" className={classes.checkboxTitleText}>
                  We only pay invoices if the following is provided correctly:
                </Typography>
                <Typography variant="body2" className={classes.toggleAllBtn} onClick={handleToggleAllCheckboxes}>
                  Toogle all
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {checkboxesValues.map(({ id, name, is_enable }) => {
                  if (name.length) {
                    return (
                      <Grid item xs={12} md={4} key={id}>
                        <CustomCheckbox
                          key={id}
                          name={name}
                          value={!!is_enable}
                          title={name}
                          onChange={handleChangeInvoiceSettings}
                        />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={12} md={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
