import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseProgress } from './BaseProgress';
import { Wrapper } from '../../shared/Wrapper';

export const useStyles = makeStyles(() => ({
  card: {
    padding: '20px',
  },
}));

interface IProps {
  value: number;
}

export const MyCompaniesProgress: React.FC<IProps> = props => {
  const { value } = props;

  return (
    <>
      {/* @ts-ignore */}
      <Wrapper isDefaultPadding>
        <BaseProgress
          title="Claimed companies completeness"
          subtitle="Earn achivement for 100% claimed companies comleteness"
          value={value}
        />
      </Wrapper>
    </>
  );
};
