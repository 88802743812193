import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import FormContainer from 'src/wizards/HomePageWizard/FormContainer/FormContainer';

import bannerImage from 'src/assets/home/new-banner-hires.jpg';

import { useStyles } from './styles';

export const Banner = () => {
  const classes = useStyles();
  const isLogged = useSelector(state => state.user.isAuthenticated);
  const [isWizardStarted, setIsWizardStarted] = useState(false);

  const handleGetStarted = () => setIsWizardStarted(true);

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item xs={12} md={5} className={classes.leftColumn}>
        <Typography variant="h1">Get paid on time</Typography>
        <Box mt={2.5} mb={6}>
          <Typography style={{ fontSize: 20 }}>
            Take control of collections to solve your cash
            <br />
            flow problems
          </Typography>
        </Box>
        {isWizardStarted ? (
          <FormContainer />
        ) : (
          <>
            {isLogged ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to="/account/dashboard"
              >
                Get started
              </Button>
            ) : (
              <Button variant="contained" color="primary" className={classes.button} onClick={handleGetStarted}>
                Get started
              </Button>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={7} className={classes.bannerContainer}>
        <img src={bannerImage} alt="banner" className={classes.banner} />
      </Grid>
    </Grid>
  );
};
