import { api } from '../index';

export const setCalendar = data => api.post('/calendar/save', data);

export const getAvailableCalendars = () => api.get('/calendar/available');

export const syncEventWithCalendar = data => api.post('/calendar/sync-events', data);

export const calendarAuthorize = () => api.get('/calendar/authorize');

export const calendarCallback = (calendar, data) => api.post(`/calendar/callback/${calendar}`, data);

export const syncUpdatedEvent = data => api.post('/calendar/sync-event', data);
