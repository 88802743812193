/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { showErrorNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';
import { isEmptyObj } from '@utils/isEmptyObj';
import { ReactComponent as TimesIcon } from '@assets/svg/icons/times.svg';

import { Notification } from '@api/requests';

import { getUser } from '@store/thunks/auth';

import clsx from 'classnames';
import { useStyles } from './styles';

export const NotificationsLine = () => {
  const classes = useStyles();

  const userData = useSelector(({ user }) => user.currentUserData);
  const isLogged = useSelector(state => state.user.isAuthenticated);

  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (!isEmptyObj(userData)) {
      setNotifications(userData.notifications);
    }
  }, [userData]);

  useEffect(() => {
    let timer;
    if (notifications.length) {
      setTimeout(() => {
        timer = setShowNotifications(true);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [notifications]);

  const handleCloseNotification = async notificationId => {
    try {
      await Notification.hidden(notificationId);
      const token = JSON.parse(localStorage.jwt);
      dispatch(getUser(token.jwt));
    } catch (e) {
      showErrorNotification(err.DEFAULT);
    }
    const notificationsClone = notifications.map(el => ({ ...el }));
    const newNotifications = notificationsClone.filter(el => el.id !== notificationId);
    setNotifications(newNotifications);
  };

  return (
    <>
      {isLogged && (
        <div className={classes.notificationsWrapper}>
          {notifications.length
            ? notifications.map((notification, index) => {
                if (index === 0) {
                  return (
                    <div
                      key={notification.id}
                      className={clsx(classes.notificationLineBox, {
                        [classes.notificationLineBoxActive]: showNotifications,
                        [classes[`${notification.status}`]]: true,
                      })}
                    >
                      {showNotifications && (
                        <>
                          <div className={classes.textBox}>
                            <p className={classes.notificationText}>{notification.message}</p>
                            <Link className={classes.notificationLink} to={`/${notification.link}`}>
                              {notification.additional.link_name}
                            </Link>
                          </div>
                          <div
                            className={classes.timesIconBox}
                            onClick={() => handleCloseNotification(notification.id)}
                          >
                            <TimesIcon fill="#ffffff" />
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
                return [];
              })
            : null}
        </div>
      )}
    </>
  );
};
