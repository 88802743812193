import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  inputWrapper: {
    maxWidth: 320,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#5E6369',
  },
  datePicker: {
    marginTop: '4px',
  },
  stepTitle: {
    marginBottom: '20px',
    fontSize: '20px',
    lineHeight: '25px',
    color: '#040C14',
  },
  stepSubText: {
    color: '#4D4D4D',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
}));
