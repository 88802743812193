import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Benefit } from './Benefit';

import { useStyles } from './styles';

const LEFT_BENEFITS = [
  'Get paid on time',
  'Improve your cash flow so you can plan further ahead',
  'Worry less about outstanding invoices',
  'Regain control of the most important part of your business',
  'Improve your credit score',
  'Demonstrate to lenders you are a low-risk borrower',
];

const RIGHT_BENEFITS = [
  'Check on the payment conduct of prospective customers before you do business with them',
  'Get paid faster than your competitors',
  'Improve relationships with your customers',
  'Change the way payments are handled in your industry',
];

export const Benefits = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mb={8}>
        <Typography className={classes.title}>Benefits of RIABU</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            {LEFT_BENEFITS.map(benefit => (
              <Grid key={benefit} item xs={12}>
                <Benefit benefit={benefit} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            {RIGHT_BENEFITS.map(benefit => (
              <Grid key={benefit} item xs={12}>
                <Benefit benefit={benefit} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
