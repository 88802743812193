import React from 'react';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green_b, black_main } from '@theme/customTheme';

const CustomCheckboxComponent = ({
  name,
  value,
  title,
  onChange,
  custompadding,
  disabled = false,
  colorBox,
  textStyle = null,
}) => {
  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          checked={value}
          onChange={onChange}
          name={name}
          custompadding={custompadding}
          disabled={disabled}
          colorBox={colorBox}
        />
      }
      label={
        <Typography style={{ ...textStyle, userSelect: 'none' }} variant="body2">
          {title}
        </Typography>
      }
    />
  );
};

const StyledCheckbox = withStyles({
  root: {
    color: ({ colorBox }) => colorBox || black_main,
    '&$checked': {
      color: green_b,
    },
    padding: ({ custompadding }) => (custompadding === 'true' ? '4px' : '9px'),
    marginLeft: ({ custompadding }) => (custompadding === 'true' ? '5px' : 'none'),
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const CustomCheckbox = React.memo(CustomCheckboxComponent);
