import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { StyledInputLabel } from '../StyledInputLabel';

export const MARK_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

interface IExtendedInputLabelProps {
  markType?: any;
  label: string;
  [key: string]: any;
}

export const useStyles = makeStyles(theme => ({
  mark: {
    display: 'inline-block',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: '5px',
    transition: 'background 300ms',
    background: ({ markType }: IExtendedInputLabelProps) => {
      switch (markType) {
        case MARK_TYPE.ERROR:
          return 'red';
        case MARK_TYPE.SUCCESS:
          return theme.palette.primary.main;
        default:
          return theme.palette.primary.main;
      }
    },
  },
}));

export const ExtendedInputLabel: React.FC<IExtendedInputLabelProps> = props => {
  const { label, markType, ...otherProps } = props;
  const classes = useStyles(props);
  return (
    <StyledInputLabel {...otherProps}>
      {markType && <span className={classes.mark} />}
      <span style={{ color: '#5E6369', textTransform: 'none', fontSize: '14px' }}>{label}</span>
    </StyledInputLabel>
  );
};
