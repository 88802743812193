import { api } from '../index';

export interface ICompanySearch {
  id: number;
  name: string;
}

export interface ICompany {
  company_id: number;
  id: number;
  name: string;
  uen: string;
  uen_status: string;
  uen_registered_date: Date;
  address: null;
  postcode: null;
  entity_type: string;
  company_url: null;
  country: string;
  logo: null;
  currency: string;
  standard_credit_terms: number;
  procurement_policies: null;
  comment: null;
  active: number;
  created_at: Date;
  updated_at: Date;
}

export interface IMasterFile {
  id: number;
  user_id: number;
  company_id: string;
  customer_since: Date;
  agreed_credit_terms_day: number;
  procurement_portal: string;
  accounts_payable_contact_name: string;
  accounts_payable_email_address: string;
  accounts_payable_phone_number: string;
  accounts_payable_phone_code: string;
  email_to_send_e_invoices_to: string;
  special_instructions: number;
  instructions: string;
  other_emails: string;
  file: string;
  created_at: Date;
  updated_at: Date;
  colleagues_email: string;
  file_old_name: string;
  credit_policy_as_at: Date;
  hidden: number;
  claim_id: number;
  high_risk_customer: boolean;
}

export interface IReview {
  id: number;
  user_id: number;
  company_id: string;
  country_id: number;
  website: string;
  rating: number;
  supplier_type: number;
  company_procurement_portal: number;
  via_regular_email: number;
  hard_copy_delivered_with_the_product: number;
  hard_copy_snail_mailed: number;
  confirmation_invoice_issued_instruction_of_customer: number;
  purchase_order: number;
  signed_hard_copy_quotation: number;
  digitally_signed_quotation: number;
  in_person_phone_conversation: number;
  via_call_centre: number;
  via_our_website: number;
  other: number;
  confirmation_product_delivered_according_specification: number;
  correct_information_confirmation: number;
  status: number;
  created_at: Date;
  updated_at: Date;
  phone: string;
  master_file_id: string;
  recommended_by: string;
  supplier_type_name: string;
}

export interface ICompanyInfo {
  company: ICompany;
  master_file: IMasterFile[];
  review: IReview[];
  dso_report: number;
}

export const find = (params: any, cancelToken: any) => api.get('/company/find', { params, cancelToken });
export const search = (name: string) => api.get<ICompanySearch[]>(`/company/search`, { params: { name } });
export const getInfo = (id: string) => api.get<ICompanyInfo>(`/company/info/${id}`);
