import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
// Table Cell
const useTableCellStyles = makeStyles(() => ({
  head: {
    border: 'unset',
    padding: '0 16px',
    fontSize: '12px',
    lineHeight: '16px',
  },
  body: {
    fontSize: '12px',
    lineHeight: '16px',
    border: 'unset',
    '&:first-child': {
      borderBottomLeftRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    '&:last-child': {
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
}));
export const StyledTableCell: React.FC<TableCellProps> = props => {
  const { children, ...otherProps } = props;
  const classes = useTableCellStyles();
  return (
    <TableCell classes={classes} {...otherProps}>
      {children}
    </TableCell>
  );
};

// Table Row
const useTableRowStyles = makeStyles(() => ({
  root: {
    background: '#f7f7f8',
  },
  head: {
    background: 'transparent',
  },
}));
export const StyledTableRow: React.FC<TableRowProps> = props => {
  const { children, ...otherProps } = props;
  const classes = useTableRowStyles();
  // @ts-ignore
  return (
    <TableRow classes={classes} {...otherProps}>
      {children}
    </TableRow>
  );
};

const useStyles = makeStyles({
  table: {
    borderSpacing: '0 16px',
    borderCollapse: 'separate',
  },
});
export const SimpleTable: React.FC<{ tableHead?: any }> = props => {
  const { children, tableHead } = props;
  const classes = useStyles();

  return (
    <TableContainer style={{ overflow: 'auto' }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>{tableHead}</TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
