/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ReCaptchaComponent = ({ action: actionProp, onHandleGenerateCaptchaToken, match }) => {
  const [isReady, setIsReady] = useState(false);

  let scriptLoc;
  let widgetLoc;

  const onLoad = () => {
    const widget = document.createElement('div');
    widget.id = 'g-recaptcha';

    const root = document.getElementById('root');

    widgetLoc = root.appendChild(widget);

    if (!isReady) {
      window.grecaptcha.render('g-recaptcha', {
        sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        size: 'invisible',
      });

      window.grecaptcha.ready(() => {
        setIsReady(true);
      });
    }
  };

  const loadScript = () => {
    window.captchaOnLoad = onLoad;
    const url = 'https://www.google.com/recaptcha/api.js';
    const queryString = '?onload=captchaOnLoad&render=explicit';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url + queryString;
    script.async = true;
    script.defer = true;
    script.id = 'g-recaptcha-script';

    scriptLoc = document.body.appendChild(script);
  };

  const executeCaptcha = () => {
    if (!isReady) {
      throw new Error("Captcha can be executed only when it's ready.");
    }

    return window.grecaptcha.execute({ action: actionProp });
  };

  const cleanUp = () => {
    const root = document.getElementById('root');
    const widget = document.querySelector('#g-recaptcha');

    if (widgetLoc) {
      root.removeChild(widgetLoc);
    }
    if (widget) {
      widget.remove();
    }
    if (scriptLoc) {
      document.body.removeChild(scriptLoc);
    }
  };

  useEffect(() => {
    loadScript();
  }, []);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  useEffect(() => {
    if (isReady) {
      onHandleGenerateCaptchaToken(executeCaptcha);
    }
  }, [isReady]);

  return <></>;
};

export const ReCaptcha = withRouter(ReCaptchaComponent);
