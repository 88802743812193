import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

export const TalkToAnExpert = () => {
  const classes = useStyles();
  const [showIframe, setShowIframe] = useState(false);

  return (
    <div className={classes.container}>
      <Typography style={{ color: '#A8ADB2' }} align="center">
        Talk to an Expert
      </Typography>
      {showIframe ? (
        <Box className={classes.iframeWrapper}>
          <iframe
            src="https://riabu.setmore.com"
            width="100%"
            height="100%"
            title="Loaded RIABU Setmore iframe"
            style={{
              border: 'none',
              overflow: 'hidden',
            }}
          />
        </Box>
      ) : (
        <>
          <Box mt={3.5} mb={3.5}>
            <Typography className={classes.title} align="center">
              Frustrated by customers who aren’t
              <br />
              paying you on time?
            </Typography>
          </Box>
          <Box mb={5}>
            <Typography style={{ color: '#5E6369' }} align="center">
              Get fast, actionable advice on what you can do right now to get your customers to pay you on time.
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <Button variant="contained" color="primary" style={{ width: 240 }} onClick={() => setShowIframe(true)}>
              Start
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};
