import { createTheme } from '@material-ui/core/styles';

export const grey_dark = '#B3B3B3';
export const grey_medium = '#CBCFD3';
export const grey_light = '#F7F7F7';
export const grey_potty = '#FAFAFA';
export const grey_shades = '#99A6BF';
export const grey_label = '#A1A1A1';

export const black_main = '#1A1A1A';
export const black_light = '#4D4D4D';

export const green_b = '#89CC21';
export const blue_b = '#0FA5F0';
export const orange_b = '#F4880C';
export const red_b = '#E74040';

export const blueContainedBtn = {
  background: blue_b,
  color: 'white',
};

export const blueOutlinedBtn = {
  background: 'rgba(19, 181, 234, 0.1)',
  color: blue_b,
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#89CC21',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: red_b,
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F7F7F7',
      paper: '#FFFFFF',
    },
    q: {
      background: '#F7F7F7',
      color: '#4D4D4D',
      textDecoration: 'none',
    },
  },

  shadows: [
    'none',
    // form
    '4px 8px 24px rgba(0, 0, 0, 0.04)',
    // bloks
    '8px 8px 12px rgba(0, 0, 0, 0.02)',
    // popup
    '4px 16px 32px rgba(0, 0, 0, 0.08)',
    // accordion
    '8px 8px 16px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.04)',
    // search home page
    '8px 16px 40px rgba(29, 43, 86, 0.07), 4px 4px 4px rgba(29, 43, 86, 0.04)',
    // img frame 3 top 3 countries && mauntain
    '16px 32px 64px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.04)',
    // img frame company is talking
    '8px 24px 48px rgba(0, 0, 0, 0.03), 0px 0px 8px rgba(0, 0, 0, 0.04)',
  ],

  typography: {
    fontFamily: 'Open Sans',
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      color: black_light,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      height: '100%',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      color: black_main,
      letterSpacing: '0.02em',
      '&:hover': {
        transition: '0.3s',
        textDecoration: 'underline',
      },
    },
    h1: {
      fontSize: '56px',
      lineHeight: '73px',
      fontWeight: 'bold',
      color: black_main,
      '@media (max-width:724px)': {
        fontSize: '28px',
        lineHeight: '36px',
      },
    },
    h2: {
      fontSize: '32px',
      lineHeight: '48px',
      '@media (max-width:480px)': {
        fontSize: '24px',
        lineHeight: '31px',
      },
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold',
      color: black_main,
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold',
      '@media (max-width:480px)': {
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    h5: {
      fontSize: '18px',
      lineHeight: '32px',
      '@media (max-width:480px)': {
        fontSize: '16px',
        lineHeight: '32px',
      },
    },
    h6: {
      fontSize: '18px',
      lineHeight: '32px',
      fontWeight: 600,
      color: black_main,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflowX: 'hidden',
        },
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
    },
    MuiButton: {
      root: {
        padding: '12px 20px',
        fontWeight: 600,
        fontSize: '14px',
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        borderRadius: '8px',
      },
      outlinedSizeLarge: {
        padding: '15px 20px !important',
      },
      sizeLarge: {
        padding: '16px 20px',
      },
      outlinedPrimary: {
        padding: '11px 20px',
      },
      outlinedSecondary: {
        padding: '11px 20px',
      },
      outlined: {
        '&:hover': {
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 8px rgba(0, 0, 0, 0.05);',
        },
        '&$disabled': {
          borderColor: grey_dark,
          backgroundColor: '#FFFFFF',
          color: grey_dark,
        },
      },
      contained: {
        '&$disabled': {
          backgroundColor: grey_medium,
          color: '#FFFFFF',
        },
      },
    },
    MuiTextField: {
      root: {
        minWidth: '100%',
      },
    },
    MuiInputBase: {
      root: {
        height: 'auto',
        width: '100%',
      },
      input: {
        height: 'auto',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: `${grey_medium}`,
        },
        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: `${grey_medium}`,
        },
      },
      input: {
        padding: '12px 16px',
        '&::placeholder': {
          fontSize: '14px',
          color: grey_dark,
          lineHeight: '24px',
          textTransform: 'capitalize',
          opacity: 1,
        },
        color: `${black_main}`,
        fontSize: '14px',
        lineHeight: '24px',
      },
    },

    MuiMenu: {
      list: {
        padding: '8px',
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '4px 6px 32px rgba(0, 0, 0, 0.2)',
      },
    },
  },
});
