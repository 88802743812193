import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 60,
    paddingBottom: 60,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  title: {
    color: '#040C14',
    fontSize: 40,
    fontWeight: 'bold',
    position: 'relative',

    '&::before': {
      position: 'absolute',
      content: "' '",
      width: 40,
      height: 2,
      backgroundColor: '#000000',
      bottom: -32,
      left: 0,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
}));
