import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  menuWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '40px',
  },
  mobileMenuWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    justifyContent: 'flex-start',
    gap: '32px',
    width: '100%',
    zIndex: 1500,
    backgroundColor: 'white',
    position: 'relative',
  },
  mobileDrawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    top: '64px',
    zIndex: 999,
  },
  drawerPaper: {
    top: '64px',
    height: 'calc(100vh - 64px)',
    width: '100%',
    zIndex: 999,
    backgroundColor: '#fff',
    borderTop: '1px solid #D8DFE6',
    padding: '0px 20px',
  },
  backdrop: {
    top: '64px',
    height: 'calc(100vh - 64px)',
    zIndex: 998,
  },
  searchWrapper: {
    marginLeft: 'auto',
    width: '100%',
    maxWidth: '600px',
  },

  searchCompany: {
    zIndex: 9999,
  },
  divider: {
    height: '1px',
    backgroundColor: '#D8DFE6',
  },
  mibileMenuItem: {
    margin: '20px 0',
  },
  menuLink: {
    textDecoration: 'none',
    color: '#1A1A1A',
    fontWeight: 600,
    fontSize: '18px',
  },
});
