import { api } from '../index';

export const getItemsList = masterFileId => api.get(`/order/${masterFileId}`);

export const getList = (masterFileId, params) => api.get(`/order/get-list/${masterFileId}`, { params });

export const create = data => api.post('/order/create', data);

export const createMain = masterFileId => api.post('/order/create-main', masterFileId);

export const deleteOrder = orderId => api.get(`order/delete/${orderId}`);

export const deleteMainOrder = orderId => api.get(`order/delete-main/${orderId}`);

export const updateCustom = data => api.post('/order/update', data);

export const updateDefault = data => api.post('/order/update-default', data);

export const changeSort = data => api.post('/order/change-sort-order', data);

export const updateNumber = data => api.post('/order/update-purchase-number', data);

export const updateOpportunity = data => api.post('/order/opportunity', data);
