import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { grey_light, black_main } from '@theme/customTheme';

export const CustomMenuItem = withStyles({
  root: {
    padding: ({ padding }) => padding || '8px',
    transition: '.3s',
    background: 'none !important',
    borderBottom: `1px solid ${grey_light}`,
    letterSpacing: '0.02em',
    color: ({ color }) => color || black_main,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ justify }) => justify && justify,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      transition: '.3s',
      textDecoration: 'underline',
    },
  },
  selected: {
    fontWeight: 600,
    '&:hover': {
      transition: '.3s',
      textDecoration: 'none',
    },
  },
})(MenuItem);
