import { api } from '../index';

export const createCustomer = data => api.post('/wizard/customer', data);

export const createOrder = data => api.post('/wizard/order', data);

export const addUserData = data => api.post('/wizard/profile', data);

export const skip = () => api.post('/wizard/skip');

export const startTrail = () => api.post('/wizard/start-trial');
