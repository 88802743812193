import { api } from '../index';

export const uploadAvatar = data => api.post('/profile/upload-avatar', data);

export const removeAvatar = () => api.delete('/profile/remove-avatar');

export const update = data => api.post('/profile/update', data);

export const userData = () => api.get('/profile/view');

export const changePassword = data => api.post('/profile/change-password', data);
