import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { blue_b } from '@theme/customTheme';
import globalCompactImg from 'src/assets/img/global-compact.png';
import { ReactComponent as Instagram } from '@assets/svg/icons/Instagram.svg';
import { ReactComponent as Linkedin } from '@assets/svg/icons/Linkedin.svg';
import { ReactComponent as Facebook } from '@assets/svg/icons/Facebook.svg';
import { ReactComponent as Twitter } from '@assets/svg/icons/Twitter.svg';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();
const footerText = `Copyright © ${currentYear} RIABU LLP. All rights reserved.`;

export const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footerBox}>
      <Box className={classes.item}>
        <img src={globalCompactImg} alt="Global Compact" style={{ maxWidth: '200px' }} />
      </Box>
      <Box className={classes.item}>
        <Typography className={classes.footerText}>{footerText}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography className={classes.footerText} style={{ color: '#1A1A1A' }}>
          <Link to={'/privacy-policy'}>Privacy Policy</Link>
        </Typography>
      </Box>
      <Box className={classes.item}>
        <Typography className={classes.footerText} style={{ color: '#1A1A1A' }}>
          <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
        </Typography>
      </Box>
      <Box className={classes.list}>
        <Box className={classes.listItem}>
          <Typography className={classes.footerText} style={{ color: blue_b, cursor: 'pointer' }}>
            <a href="https://www.instagram.com/riabucommunity/?hl=en">
              {' '}
              <Instagram />
            </a>
          </Typography>
        </Box>

        <Box className={classes.listItem}>
          <Typography className={classes.footerText} style={{ color: blue_b, cursor: 'pointer' }}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.linkedin.com/company/riabu/posts/?feedView=all">
              <Linkedin className={classes.icon} />
            </a>
          </Typography>
        </Box>
        <Box className={classes.listItem}>
          <Typography className={classes.footerText} style={{ color: blue_b, cursor: 'pointer' }}>
            <a href="https://www.facebook.com/BeFirstInLineToGetPaid">
              <Facebook className={classes.icon} />
            </a>
          </Typography>
        </Box>
        <Box className={classes.listItem}>
          <Typography className={classes.footerText} style={{ color: blue_b, cursor: 'pointer' }}>
            <a href="https://twitter.com/riabullp?lang=en">
              <Twitter className={classes.icon} />
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
