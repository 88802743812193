import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    width: 240,
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepWrapper: {
    marginTop: '40px',
    marginBottom: '10px',
  },
  submitSuccess: {
    display: 'flex',
    gap: '2px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitSuccessText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#5E6369',
  },
}));
