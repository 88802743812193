/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { showErrorNotification } from 'src/components/common/Notifications';

export const useFetch = (apiMethod: Function, byUrl = false, otherParams: any = {}): any => {
  const { isDataUnpacked = true } = otherParams;
  const [response, setResponse] = useState(null);
  const [isLoading, setLoadingStatus] = useState(false);
  const [error, setError] = useState(null);
  const [params, setParams] = useState(null);

  const doFetch = useCallback(reqParams => {
    setParams(reqParams);
    setLoadingStatus(true);
  }, []);

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;

    if (!isLoading) return;

    let requestOptions = null;
    if (byUrl) {
      requestOptions = params;
    } else {
      // @ts-ignore
      requestOptions = { ...params };
    }

    apiMethod(requestOptions)
      .then((res: any) => {
        if (!skipGetResponseAfterDestroy) {
          if (isDataUnpacked) {
            setResponse(res.data);
          } else {
            setResponse(res);
          }
          setLoadingStatus(false);
        }
      })
      .catch((err: any) => {
        if (!skipGetResponseAfterDestroy) {
          setLoadingStatus(false);
          setError(err);
          showErrorNotification('Something was wrong');
        }
      });

    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [apiMethod, isLoading, params]);

  return [{ response, isLoading, error }, doFetch, setResponse];
};
