import { api } from '../index';

export const start = (params = {}) => {
  return api.get('/accreditation/start', {
    params,
  });
};

export const resume = (params = {}) => {
  return api.get('/accreditation/continue', {
    params,
  });
};

export const saveAnswer = data => api.post('/accreditation/send-answer', data);

export const saveFiles = data => api.post('/accreditation/save-files', data);

export const getUserData = (params = {}) => {
  return api.get('/accreditation', {
    params,
  });
};

export const saveUserData = data => api.post('/accreditation/fill-fields', data);

export const oneMoreTime = (params = {}) => {
  return api.get('/accreditation/one-more-time', {
    params,
  });
};
