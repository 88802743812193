import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  externalLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  childrenBox: {
    flexGrow: 1,
    width: '100%',
    background: '#FFFFFF',
  },
  footerBox: {
    flexShrink: 0,
  },
}));
