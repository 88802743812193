import React, { useMemo } from 'react';

import { Button, CircularProgress } from '@material-ui/core';
import { CenteredBox } from '@components/common/CenteredBox';

import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { PaymentProcessesForm } from './PaymentProcessesForm';
import { DiscrepanciesManagementForm } from './DiscrepanciesManagementForm';
import { Employees } from './Employees/Employees';
import { Wrapper } from '../../../shared/Wrapper';
import { isMobile } from '../../../utils/mobileDetect';
import { getQueryStringValues } from '../../../utils/queryString';

const useStyles = makeStyles({
  buttonsList: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '16px',
    gridAutoColumns: 'minmax(max-content, 1fr)',
    overflow: 'auto',
    marginBottom: '24px',
  },
});

const tabs = [
  { name: 'Information', query: 'info' },
  { name: 'Resolution Center', query: 'resolution' },
  { name: 'Employees', query: 'employees' },
];

const MyCompanyUpdateFormComponent = ({ match, collectionsData, isLoading }) => {
  const { claim_id, company_id } = match.params;
  const classes = useStyles();
  const history = useHistory();
  const currentTab = useMemo(() => {
    return getQueryStringValues(history.location.search).tab || tabs[0].query;
  }, [history.location.search]);

  return (
    <>
      {isLoading ? (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <Wrapper padding={isMobile ? '0px' : '40px'} borderRadius="16px" shadow="false">
          <div className={classes.buttonsList}>
            {tabs.map(tab => {
              return (
                <Button
                  onClick={() => {
                    history.push(`?tab=${tab.query}`);
                  }}
                  color="primary"
                  variant={currentTab === tab.query ? 'contained' : 'outlined'}
                  style={{ padding: '12px', lineHeight: '16px' }}
                >
                  {tab.name}
                </Button>
              );
            })}
          </div>

          {currentTab === tabs[0].query && (
            <PaymentProcessesForm claimId={claim_id} companyId={company_id} collectionsData={collectionsData} />
          )}
          {currentTab === tabs[1].query && (
            <DiscrepanciesManagementForm claimId={claim_id} companyId={company_id} collectionsData={collectionsData} />
          )}
          {currentTab === tabs[2].query && <Employees companyId={claim_id} />}
        </Wrapper>
      )}
    </>
  );
};

export const MyCompanyUpdateForm = withRouter(MyCompanyUpdateFormComponent);
