import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { red_b } from '@theme/customTheme';

export const CustomHelperText = ({ children, helperTextPosAbsl = false }) => (
  <StyledFormHelperText helpertextposabsl={helperTextPosAbsl.toString()}>{children}</StyledFormHelperText>
);

const StyledFormHelperText = styled(FormHelperText)({
  '&.MuiFormHelperText-root': {
    transition: '.3s',
    margin: '0 !important',
    marginTop: '2px !important',
    fontSize: '10px',
    lineHeight: '13px',
    color: red_b,
    position: ({ helpertextposabsl }) => helpertextposabsl === 'true' && 'absolute',
    top: ({ helpertextposabsl }) => helpertextposabsl === 'true' && '100%',
  },
});
