import { makeStyles } from '@material-ui/core/styles';
import { grey_light } from '@theme/customTheme';

export const useStyles = makeStyles(theme => ({
  headerBox: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.05)',
    position: 'relative',
  },
  mobileBurgerMenu: {
    opacity: 0,
  },
  mobileHeaderBox: {
    background: '#FFFFFF',
    margin: '10px 0',
  },
  container: {
    padding: '8px 0',
    flexGrow: 1,
  },
  logoImg: {
    display: 'block',
    width: '96px',
    height: 'auto',
  },
  logoBox: {
    cursor: 'pointer',
  },
  langOptions: {
    width: '70px',
  },
  userActionBtn: {
    background: 'none',
    '&:hover': {
      background: grey_light,
    },
  },
  userActionBtnActive: {
    background: grey_light,
  },
  userActionList: {
    minWidth: '150px',
  },
  menuLink: {
    textDecoration: 'none',
    color: '#1A1A1A',
    fontWeight: 600,
    fontSize: '16px',
  },
  keaderDesktop: {
    display: 'flex',
  },
  mobileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 0',
  },
  paper: {
    borderRadius: '8px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.05)',
    position: 'relative',

    padding: '8px 0',
    flexGrow: 1,
  },
  logoWrapper: {
    width: '100%',
    maxWidth: '96px',
  },

  menuWrapper: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    gap: '40px',
    [theme.breakpoints.down('md')]: {
      gap: '20px',
    },
  },
  searchWrapper: {
    width: '100%',
    maxWidth: '600px',
    marginLeft: 'auto',
    border: '1px solid grey',
    borderRadius: '16px',
  },
}));
