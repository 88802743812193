export const createOptions = (index, value) => ({
  id: index + value,
  optionsValue: value,
  title: value,
});

export const createCountryOptions = (paramsId, shortName, fullName) => ({
  id: paramsId,
  optionsValue: paramsId,
  title: `${fullName} (${shortName})`,
});

export const createDiscrepancyOptions = (paramsId, name) => ({
  id: paramsId,
  optionsValue: paramsId,
  title: name,
});

export const createClaimOptions = (paramsId, name) => ({
  id: paramsId,
  optionsValue: paramsId,
  title: name,
});

export const createClaimDaysOptions = days => ({
  id: days,
  optionsValue: days,
  title: days,
});
