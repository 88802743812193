import axios from 'axios';
import { showErrorNotification } from '@components/common/Notifications';
import { err } from '@constants/messages';

export const api = axios.create({
  baseURL: process.env.REACT_APP_DEV_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response && error.response.status) {
      const { status } = error.response;

      if (status === 404) {
        showErrorNotification(err.NOTFOUND);
        //   history.replace('/notfound');
      }

      if (error.message === 'Network Error' && !error.response) {
        showErrorNotification(err.NETWORK);
      }

      if (status === 401) {
        showErrorNotification(err.UNAUTHORIZED);
      }

      if (status === 413) {
        showErrorNotification(err.LARGE_REQ);
      }

      if (status === 500) {
        showErrorNotification(err.SERVER);
      }
    }

    return Promise.reject(error);
  }
);
