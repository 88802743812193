import { api } from '../index';

export const getCollections = () => api.get('/master-file/create');

export const create = data => api.post('/master-file/create', data);

export const list = params => api.get('/master-file/list', { params });

export const update = (data, id) => api.post(`/master-file/update/${id}`, data);

export const hide = id => api.get(`/master-file/hide/${id}`);

export const show = id => api.get(`/master-file/show/${id}`);

export const view = id => api.get(`/master-file/view/${id}`);
