import React from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CustomPhoneInputComponent = ({ countryCode, inputValue, name, onHandleChange, onBlur, error }) => {
  return (
    <PhoneInputBox error={error.toString()}>
      <PhoneInput
        name={name}
        onBlur={onBlur}
        value={inputValue}
        country={countryCode}
        inputStyle={{ width: '100%', height: '48px' }}
        onChange={(value, country) => onHandleChange(value, country)}
      />
    </PhoneInputBox>
  );
};

const PhoneInputBox = styled(Box)({
  '& .react-tel-input .form-control': {
    borderColor: ({ error }) => error === 'true' && '#f44336',
  },

  '& .react-tel-input .flag-dropdown': {
    borderTop: ({ error }) => error === 'true' && '1px solid #f44336',
    borderLeft: ({ error }) => error === 'true' && '1px solid #f44336',
    borderBottom: ({ error }) => error === 'true' && '1px solid #f44336',
  },
});

export const CustomPhoneInput = React.memo(CustomPhoneInputComponent);
