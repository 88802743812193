import React, { useState, useEffect } from 'react';

import { setAuthToken } from '@utils/setAuthToken';
import jwtDecode from 'jwt-decode';

import logoSm from '@assets/img/logo-sm.png';
import { loguot, setCurrentUserTokens } from '@store/actions/auth';
import { getUser } from '@store/thunks/auth';

import { store } from '../../store';

export const CoreLayout = ({ children }) => {
  const [isLoadingUser, setLoadingUserStatus] = useState(true);

  const checkingUserAuth = () => {
    const dateNow = new Date() / 1000;

    if (localStorage.jwt && JSON.parse(localStorage.jwt)) {
      const token = JSON.parse(localStorage.jwt);
      const jwtDecoded = jwtDecode(token.jwt);

      if (jwtDecoded < dateNow) {
        store.dispatch(loguot()).then(res => setLoadingUserStatus(false));
      } else {
        setAuthToken(token.jwt);
        store.dispatch(setCurrentUserTokens(token));
        store
          .dispatch(getUser(token.jwt))
          .then(res => setLoadingUserStatus(false))
          .catch(e => {
            store.dispatch(loguot());
            setLoadingUserStatus(false);
          });
      }
    } else {
      setLoadingUserStatus(false);
      localStorage.removeItem('jwt');
    }
  };

  useEffect(() => {
    checkingUserAuth();
  }, []);

  if (isLoadingUser) {
    return (
      <div className="coreLayout-wrapper">
        <img src={logoSm} alt="logosm" className="coreLayout-logoImg" />
      </div>
    );
  }
  return children;
};
